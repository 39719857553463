import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
  defaultFont,
} from "assets/jss/material-dashboard-react.js";

const dashboardStyle = (theme) => ({
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px",
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",

    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  pointer: {
    cursor: "pointer",
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
    transition: "1.5s",
  },

  cardTitleSub: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "0.9rem",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
    transition: "1.5s",
  },
  cardTitleDark: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
    transition: "1.5s",
  },
  cardBodyDisponivel: {
    background: "#282b2f !important",
    color: "#fff !important",
    transition: "1s",
    boxShadow: "0 0 14px 0 rgba(0,128,0, 0.56)",
    boxSizing: "border-box",
    "&:hover": { boxShadow: "0 0 18px 0 rgba(0,128,0, 0.4)" },
  },
  cardBodyIndisponivel: {
    background: "#282b2f !important",
    color: "#fff !important",
    transition: "1s",
    boxShadow: "0 0 14px 0 rgba(255,0,0, 0.56)",
    boxSizing: "border-box",
    "&:hover": { boxShadow: "0 0 18px 0 rgba(255,0,0, 0.4)" },
  },
  cardButtonsDisponivel: {
    marginBottom: "5px",
    borderTop: "1px dotted #008000",
    paddingTop: "5px",
  },
  cardButtonsIndisponivel: {
    marginBottom: "5px",
    borderTop: "1px dotted #f44336",
    paddingTop: "5px",
  },
  appBar: {
    position: "fixed",
    background: "#fff",
    borderBotton: "#f44336 1px solid",
  },
  title: {
    color: "#fff",
    flex: 1,
  },
  title2: {
    // marginLeft: theme.spacing(2),
    textAlign: "center",
    margin: "auto",
  },
  titleOrder: {
    color: "#555555",
    flex: 1,
  },
  ListItem: {
    color: "inherit",
    "&:hover": {
      background: "none !important",
      cursor: "unset",
    },
  },
  ListItemBalcao: {
    background: "#f44336",
    color: "#fff",
    "&:hover": {
      background: "#f44336 !important",
      cursor: "unset",
      color: "#fff",
    },
  },
  listProductsValue: {
    float: "right",
  },
  tableCell: {
    fontSize: "1.20rem",
    fontWeight: 300,
  },
  cardButtons: {
    marginBottom: "15px",
    borderTop: "1px solid #eee",
    paddingTop: "20px",
  },
  cardBody: {
    background: "#ffffff !important",
    color: "#fff !important",
    transition: "1s",
    boxShadow: "0 1px 4px 0 rgba(255, 255, 255, 0.4)",
  },
  transitoButton: {
    background: "#fff",
    color: "#333",
    transition: "0.4s",
    "&:hover": { backgroundColor: "#4ca650", color: "#fff" },
  },

  statusButton: {
    background: "#fff",
    color: "#333",
    transition: "0.7s",
    "&:hover": { backgroundColor: "#333", color: "#fff" },
  },

  finalizarButton: {
    background: "#fff",
    color: "#333",
    transition: "0.4s",
    "&:hover": { backgroundColor: "#f44336", color: "#fff" },
  },

  acceptButton: {
    background: "#000099",
    transition: "0.5s",
    "&:hover": {
      background: "#333 !important",
      cursor: "unset",
      color: "#fff",
    },
  },

  rejectButton: {
    background: "#f44336",
    transition: "0.5s",
    "&:hover": {
      background: "#333 !important",
      cursor: "unset",
      color: "#fff",
    },
  },

  orderContainer: {
    marginTop: "50px !important",
  },

  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    "&, &$tableCell": {
      fontSize: "1em",
    },
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  tableHeadRow: {
    height: "56px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
    transition: "1s",
    "&:hover": {
      background: "#e4e4e4 !important",

      cursor: "pointer",
    },
  },

  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },

  cardTable: {
    background: "#f44336 !important",
  },

  paginationDiv: {
    paddingTop: 30,
    margin: "auto",
  },

  bodyDialogFinaliza: {
    paddingLeft: "20px",
    paddingRight: "20px",
    marginBottom: "50px",
  },
  fieldDialogFinaliza: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },

  center: {
    textAlign: "center",
    margin: "auto",
    paddingBottom: 40,
    paddingTop: 22,
  },

  cardFooterCardapio: {
    minHeight: "150px",
    display: "block",
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: 10,
  },
  inputMoney: {
    border: 0,

    // display: "inline-flex",
    padding: 0,
    position: "relative",

    verticalAlign: "top",
    background: "none",
    margin: 8,
    fontSize: "1rem",
    minWidth: "100%",
    borderBottom: "1px solid #8b8b8b",
    color: "#919192 !important",
  },
  inputMoney2: {
    border: 0,

    // display: "inline-flex",
    padding: 0,
    position: "relative",

    verticalAlign: "top",
    background: "none",
    margin: 8,
    fontSize: "1rem",
    maxWidth: "90%",
    borderBottom: "1px solid #8b8b8b",
  },

  labelMoney: {
    margin: 8,
    marginTop: 10,
  },
  cadastrarButton: {
    minWidth: "100%",
    margin: 8,
    marginTop: 10,
    background: "#f44336",
    color: "#fff",
    transition: "0.4s",
    "&:hover": { backgroundColor: "#fff", color: "#333" },
  },

  titleRelatorios: {
    color: "#555555",
    fontSize: "2rem",
    fontWeight: 300,
    textAlign: "center",
    margin: "auto",
  },
  titleRelatorios: {
    color: "#555555",
    fontSize: "2rem",
    fontWeight: 300,
    textAlign: "center",
    margin: "auto",
  },
  titleRelatorios2: {
    color: "#555555",
    fontSize: "1 rem",
    fontWeight: 300,
    textAlign: "center",
    margin: "auto",
    paddingTop: 69,
  },
  media: {
    height: 170,
  },
  mediaCardapio: {
    objectFit: "cover",
    width: "100%",
    height: 200,
    // transition: '0.5s',
  },
  hr: {
    borderTop: "#333 1px dashed !important",
  },

  accordionContent: {
    justifyContent: "center"
  }
});

export default dashboardStyle;
