import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import api from "../../services/api";
import Loading from "../Loading";

import swal from "sweetalert2";
import cep from "cep-promise";
// @material-ui/core components
import MaskedInput from "react-text-mask";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

import avatar from "assets/img/logo.png";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/views/darkDashboardStyle.js";

export default function UserProfile() {
  const store = useSelector((state) => state);
  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);
  const classes = useStyles();

  const [nomeEstabelecimento, setNomeEstabelecimento] = useState();
  const [email, setEmail] = useState();
  const [telefone_fixo1, setTelefoneFixo1] = useState();
  const [telefone_fixo2, setTelefoneFixo2] = useState();
  const [celular, setCelular] = useState();
  const [razaoSocial, setRazaoSocial] = useState();
  const [segmentoAtividade, setSegmentoAtividade] = useState();

  const [cnpj, setCnpj] = useState();
  const [nomeCompleto, setNomeCompleto] = useState();
  const [cpf, setCpf] = useState();
  const [cepUsuario, setCepUsuario] = useState();
  const [logradouro, setLogradouro] = useState();
  const [numero, setNumero] = useState();
  const [bairro, setBairro] = useState();
  const [cidade, setCidade] = useState();
  const [uf, setUf] = useState();
  const [tituloInicial, setTituloInicial] = useState();
  const [textoInicial, setTextoInicial] = useState();

  const [imagemLogo, setImagemLogo] = useState();
  const [imagemLogoNome, setImagemLogoNome] = useState();

  const [urlInstagram, setUrlInstagram] = useState();
  const [urlFacebook, setUrlFacebook] = useState();

  const [configs, setConfigs] = useState([]);

  const [loading, setLoading] = useState(false);

  const [refresh, setRefresh] = useState(0);

  const token = localStorage.getItem("token");

  useEffect(() => {
    setLoading(true);
    const buscaConfigs = async () => {
      await api
        .get(
          "configs",
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          setConfigs(response.data);

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          swal.fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            //  timer: 5000
          });
        });
    };

    buscaConfigs();
  }, [refresh]);

  const buscaCep = () => {
    cep(cepUsuario).then((response) => {
      //console.log(response);
      setLogradouro(response.street);
      setBairro(response.neighborhood);
      setCidade(response.city);
      setUf(response.state);
    });
  };

  const handleAtualizaConfigs = () => {
    setLoading(true);
    const data = {
      nome_estabelecimento: nomeEstabelecimento,
      email,
      telefone_fixo1,
      telefone_fixo2,
      celular,
      razao_social: razaoSocial,
      segmento_atividade: segmentoAtividade,
      cnpj,
      nome_completo: nomeCompleto,
      cpf,
      cep: cepUsuario,
      logradouro,
      numero,
      bairro,
      cidade,
      uf,
      titulo_inicial: tituloInicial,
      texto_inicial: textoInicial,
      url_instagram: urlInstagram,
      url_facebook: urlFacebook,
    };

    api
      .put(
        "atualiza-configs",
        data,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        if (response.data) {
          swal.fire({
            html: "<h4>Informações atualizadas com sucesso!</h4>",
            icon: "success",
            confirmButtonColor: "#2d3035",
            cancelButtonColor: "#2d3035",
            timer: 10000,
          });
        }

        setRefresh(refresh + 1);
      })
      .catch(() => {
        setLoading(false);

        swal.fire({
          html:
            "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
          icon: "warning",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",
          //  timer: 5000
        });

        // setTimeout(() => window.location.reload(), 5000);
      });
  };

  const changeFileLogo = async (e) => {
    setLoading(true);
    let reader = new FileReader();
    setImagemLogo(e.target.files[0]);
    setImagemLogoNome(e.target.files[0].name);
    const formData = new FormData();
    formData.append("image-logo", e.target.files[0]);
    // console.log(e.target.files[0]);
    setTimeout(() => {
      api
        .post(`imagens-site`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // console.log(response.data);
          setLoading(false);

          swal.fire({
            html: "<h4>Logo Atualizado</h4><br />",
            icon: "success",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 10000,
          });
          setRefresh(refresh + 1);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status == 400) {
            swal.fire({
              html:
                "<h4>Tipo de arquivo inválido</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "error",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              //  timer: 5000
            });
          } else {
            swal.fire({
              html:
                "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              //  timer: 5000
            });
          }
        });
    }, 1000);
  };

  const emConstrucao = () => {
    swal.fire({
      html: "<h4>Em Construção.</h4><br />",
      icon: "warning",
      confirmButtonColor: "#2d3035",
      confirmButtonText: "Ok",
      //  timer: 5000
    });
  };

  return (
    <div>
      <br />
      <br />
      <br />
      {loading ? (
        <Loading />
      ) : (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <Card className={classes.cardBody}>
                <CardHeader color="danger">
                  <h3 className={classes.cardTitleWhite}>Informações</h3>
                  <p className={classes.cardCategoryWhite}>
                    Complete todos os dados
                  </p>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Nome do Estabelecimento"
                        id="company"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          defaultValue: configs.nome_estabelecimento,
                          onChange: (e) =>
                            setNomeEstabelecimento(e.target.value),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Email"
                        id="email-address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          defaultValue: configs.email,
                          onChange: (e) => setEmail(e.target.value),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <InputMask
                        onChange={(e) => setTelefoneFixo1(e.target.value)}
                        mask={"(99) 9999-9999"}
                        formatChars={{ 9: "[0-9]" }}
                        defaultValue={configs.telefone_fixo1}
                      >
                        {() => (
                          <TextField
                            label="Telefone Fixo"
                            type="text"
                            style={{ marginTop: "28px" }}
                          />
                        )}
                      </InputMask>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <InputMask
                        onChange={(e) => setTelefoneFixo2(e.target.value)}
                        mask={"(99) 9999-9999"}
                        formatChars={{ 9: "[0-9]" }}
                        defaultValue={configs.telefone_fixo2}
                      >
                        {() => (
                          <TextField
                            label="Telefone Fixo 2"
                            type="text"
                            style={{ marginTop: "28px" }}
                          />
                        )}
                      </InputMask>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <InputMask
                        onChange={(e) => setCelular(e.target.value)}
                        mask={"(99) 99999-9999"}
                        formatChars={{ 9: "[0-9]" }}
                        defaultValue={configs.celular}
                      >
                        {() => (
                          <TextField
                            label="Telefone Celular"
                            type="text"
                            style={{ marginTop: "28px" }}
                          />
                        )}
                      </InputMask>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Razão Social"
                        id="razao-social"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          defaultValue: configs.razao_social,

                          onChange: (e) => setRazaoSocial(e.target.value),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputMask
                        onChange={(e) => setCnpj(e.target.value)}
                        mask="99.999.999/9999-99"
                        defaultValue={configs.cnpj}
                      >
                        {() => (
                          <TextField
                            label="CNPJ"
                            type="text"
                            fullWidth
                            style={{ marginTop: "27px" }}
                          />
                        )}
                      </InputMask>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    {" "}
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Nome Completo"
                        id="nome-completo"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          defaultValue: configs.nome_completo,

                          onChange: (e) => setNomeCompleto(e.target.value),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputMask
                        onChange={(e) => setCpf(e.target.value)}
                        mask="999.999.999-99"
                        defaultValue={configs.cpf}
                      >
                        {() => (
                          <TextField
                            label="CPF"
                            type="text"
                            fullWidth
                            style={{ marginTop: "27px" }}
                          />
                        )}
                      </InputMask>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Segmento de Atividade"
                        id="segmento"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          defaultValue: configs.segmento_atividade,
                          onChange: (e) => setSegmentoAtividade(e.target.value),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <InputMask
                        onChange={(e) => setCepUsuario(e.target.value)}
                        mask="99999-999"
                        defaultValue={configs.cep}
                      >
                        {() => (
                          <TextField
                            label="CEP"
                            type="text"
                            style={{ marginTop: "27px" }}
                          />
                        )}
                      </InputMask>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Logradouro"
                        id="logradouro"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: logradouro,
                          onChange: (e) => setLogradouro(e.target.value),
                          onFocus: buscaCep,
                          defaultValue: configs.logradouro,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Número"
                        id="numero"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setNumero(e.target.value),
                          defaultValue: configs.numero,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Bairro"
                        id="bairro"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: bairro,
                          onChange: (e) => setBairro(e.target.value),
                          defaultValue: configs.bairro,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Cidade"
                        id="cidade"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: cidade,
                          onChange: (e) => setCidade(e.target.value),
                          defaultValue: configs.cidade,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="UF"
                        id="uf"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: uf,
                          onChange: (e) => setUf(e.target.value),
                          defaultValue: configs.uf,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Título página inicial"
                        id="titilo-inicial"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setTituloInicial(e.target.value),
                          defaultValue: configs.titulo_inicial,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <br />
                      <br />
                      <input
                        accept="image/*"
                        className={classes.input}
                        style={{ display: "none" }}
                        id="raised-button-file3"
                        // onChange={changeFile}
                        type="file"
                      />
                      <label htmlFor="raised-button-file3">
                        <Button
                          onClick={emConstrucao}
                          variant="contained"
                          size="small"
                          style={{ width: "100%", background: "#f44336" }}
                        // className={classes.cadastrarButton}
                        // className={classes.cadastrarCategoriaButton}
                        >
                          Imagem página inicial
                        </Button>
                      </label>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <br />
                      <br />
                      <input
                        accept="image/*"
                        className={classes.input}
                        style={{ display: "none" }}
                        id="raised-button-file4"
                        // onChange={changeFile}
                        type="file"
                      />
                      <label htmlFor="raised-button-file4">
                        <Button
                          onClick={emConstrucao}
                          variant="contained"
                          size="small"
                          style={{ width: "100%", background: "#f44336" }}
                        // className={classes.cadastrarButton}
                        // className={classes.cadastrarCategoriaButton}
                        >
                          Imagem página cárdapio
                        </Button>
                      </label>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <InputLabel style={{ color: "#AAAAAA" }}></InputLabel>
                      <CustomInput
                        labelText="Texto da página inicial do site."
                        id="about-me"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setTextoInicial(e.target.value),
                          multiline: true,
                          rows: 5,
                          defaultValue: configs.texto_inicial,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="URL Facebook"
                        id="url-facebook"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setUrlFacebook(e.target.value),
                          defaultValue: configs.url_facebook,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="URL Instagram"
                        id="url-instagram"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setUrlInstagram(e.target.value),
                          defaultValue: configs.url_instagram,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <br />
                <CardFooter>
                  <Button
                    style={{
                      background: "#f44336",
                      color: "#fff",
                      marginLeft: "20px",
                    }}
                    onClick={handleAtualizaConfigs}
                    variant="contained"
                  >
                    Atualizar
                  </Button>
                </CardFooter>
                <br />
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card profile className={classes.cardBody}>
                <CardAvatar profile>
                  <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: "none" }}
                    id="raised-button-file2"
                    onChange={changeFileLogo}
                    type="file"
                  />
                  <label htmlFor="raised-button-file2">
                    <img
                      src={configs.logo}
                      alt="..."
                      className={classes.pointer}
                    />
                  </label>
                </CardAvatar>
                <CardBody profile>
                  <h3 className={classes.cardTitle}>
                    {configs.nome_estabelecimento}
                  </h3>
                  <br />
                  <div className={classes.hr} />
                  <br />

                  <h5 className={classes.cardTitleSub}>
                    Telefone Fixo 1: {configs.telefone_fixo1}
                  </h5>
                  <br />
                  <h5 className={classes.cardTitleSub}>
                    Telefone Fixo 2: {configs.telefone_fixo2}
                  </h5>
                  <br />
                  <h5 className={classes.cardTitleSub}>
                    Celular: {configs.celular}
                  </h5>
                  <br />

                  <h5 className={classes.cardTitleSub}>CNPJ: {configs.cnpj}</h5>
                  <br />

                  <h5 className={classes.cardTitleSub}>
                    Endereço: {configs.logradouro} {configs.numero},{" "}
                    {configs.bairro} {configs.cep}, {configs.cidade}{" "}
                    {configs.uf}
                  </h5>
                  <br />
                  <a
                    href={configs.url_instagram}
                    target="_blank"
                    style={{ color: "#919192", marginRight: "11px" }}
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    href={configs.url_facebook}
                    target="_blank"
                    style={{ color: "#919192", marginLeft: "11px" }}
                  >
                    <FacebookIcon />
                  </a>
                  <br />
                  <br />
                  <a href={configs.url_site} target="_blank">
                    <Button
                      variant="contained"
                      className={classes.cadastrarButton}
                    >
                      Site
                    </Button>
                  </a>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      )}
    </div>
  );
}
