const IS_DARK = {
  status: true,
};

export default function isDark(state = IS_DARK, action) {
  switch (action.type) {
    case "IS_DARK":
      return { status: action.status };
    default:
      return state;
  }
}
