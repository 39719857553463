import {
  drawerWidth,
  transition,
  container,
} from "assets/jss/material-dashboard-react.js";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    transition: "1.5s",
  },
  wrapperDark: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  swalBlack: {
    background: "#000 !important",
  },
  swalTitle: {
    color: "#fff !important",
  },

  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",

    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    background:
      "linear-gradient(to left bottom, #22252a, #212429, #202327, #1f2226, #1e2125);",
    transition: "1s !important",
    color: "#fff",
  },
  content: {
    marginTop: "80px",

    minHeight: "calc(100vh - 123px)",
  },
  container,
});

export default appStyle;
