import Ws from "@adonisjs/websocket-client/index";

const yoodemoSocket = Ws("wss://api-n2.devsmarjassi.cloud");

// const yoodemoSocket = Ws("wss://api-demo.n2delivery.com.br");

// const yoodemoSocket = Ws("ws://localhost:3333");

yoodemoSocket.connect();

const yoodemo =
  yoodemoSocket.subscribe('japa') ||
  yoodemoSocket.getSubscription('japa');

yoodemo.on("ready", () => {
  setInterval(() => {
    yoodemo.emit("verificaUsuarios", "yoo");
  }, 15000);
});

export default yoodemoSocket;
