const IS_OPEN = {
  status: false,
};

export default function abreLoja(state = IS_OPEN, action) {
  switch (action.type) {
    case "ABRE_LOJA":
      return { status: action.status };
    default:
      return state;
  }
}
