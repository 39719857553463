import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import api from "../../services/api";

import moment from "moment";
import "moment/locale/pt-br";
import swal from "sweetalert2";

import CuponsList from "./CuponsList";

import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { DatePicker } from "@material-ui/pickers";

import DoneIcon from "@material-ui/icons/Done";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/views/darkDashboardStyle.js";

function Cupons() {
  const store = useSelector((state) => state);

  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);
  const classes = useStyles();

  const [selectedInitialDate, setInitialDate] = useState(moment());
  const [selectedEndDate, setEndDate] = useState(moment(selectedInitialDate));
  const [nomeCupom, setNomeCupom] = useState("");
  const [descontoCupom, setDescontoCupom] = useState("");

  const [refreshData, setRefreshData] = useState(0);

  const token = localStorage.getItem("token");

  useEffect(() => {}, [selectedInitialDate]);

  function handleInitialDateChange(initialDate) {
    setInitialDate(initialDate);
    setEndDate(moment(initialDate));
  }

  function handleEndDateChange(endDate) {
    setEndDate(endDate);
  }

  function handleNomeCupom(cupom) {
    setNomeCupom(cupom);
  }

  function handleDescontoCupom(desconto) {
    setDescontoCupom(desconto);
  }

  async function handleInsertCupom() {
    if (!nomeCupom | !descontoCupom) {
      swal.fire({
        html:
          "<h4>Todos os campos tem que ser preenchidos</h4><br /><h5>Tente novamente!</h5>",
        icon: "error",
        confirmButtonColor: "#2d3035",
        confirmButtonText: "Ok",
        timer: 7000,
      });
    } else if (descontoCupom > 100) {
      swal.fire({
        html:
          "<h4>O desconto não pode ser maior que 100%</h4><br /><h5>Tente novamente!</h5>",
        icon: "error",
        confirmButtonColor: "#2d3035",
        confirmButtonText: "Ok",
        timer: 7000,
      });
    } else {
      const dataCupom = {
        cupom: nomeCupom.toUpperCase(),
        porcentagem_desconto: descontoCupom,
        inicio_validade: moment(selectedInitialDate).format(
          "YYYY-MM-DD 00:00:01"
        ),
        fim_validade: moment(selectedEndDate).format("YYYY-MM-DD 23:59:59"),
      };
      await api
        .post(
          "insere-cupom",
          dataCupom,
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then((response) => {
          if (response.data.cupomExistente) {
            swal.fire({
              html:
                "<h4>Este cupom já existe</h4><br /><h5>Tente novamente!</h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              timer: 7000,
            });
          } else if (response.data.cupom) {
            swal.fire({
              html: "<h4>Cupom criado com sucesso</h4><br />",
              icon: "success",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              timer: 7000,
            });
            setNomeCupom("");
            setDescontoCupom("");
            setRefreshData(refreshData + 1);
          }
        });
    }
  }

  return (
    <>
      <GridContainer alignItems="center" className={classes.createCupomTitle}>
        <Grid xs={12} sm={12} md={12} className={classes.center}>
          <br />
          <br />
          <Typography variant="h6" className={classes.cardTitle}>
            Criar Cupom
          </Typography>
        </Grid>
        <br />
      </GridContainer>
      <GridContainer spacing={3}>
        <Grid container spacing={3} className={classes.formCupom}>
          <Grid item sm={3}>
            <DatePicker
              autoOk
              label="Data Ínicio"
              disablePast
              value={selectedInitialDate}
              locale="pt-br"
              format="DD/MM/YYYY"
              cancelLabel="Cancelar"
              onChange={(e) => handleInitialDateChange(e)}
            />
          </Grid>
          <Grid item sm={3}>
            <DatePicker
              autoOk
              disablePast
              label="Data Final"
              locale="pt-br"
              format="DD/MM/YYYY"
              cancelLabel="Cancelar"
              minDate={selectedEndDate}
              value={selectedEndDate}
              onChange={(e) => handleEndDateChange(e)}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              onChange={(e) => handleNomeCupom(e.target.value)}
              value={nomeCupom}
              label="Nome do Cupom"
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              onChange={(e) => handleDescontoCupom(e.target.value)}
              value={descontoCupom}
              label="Desconto %"
              type="number"
            />
          </Grid>
          <Grid item sm={1}>
            <Button
              onClick={handleInsertCupom}
              variant="contained"
              className={classes.insertCupomButton}
            >
              <DoneIcon />
            </Button>
          </Grid>
        </Grid>
      </GridContainer>

      <br />
      <br />
      <br />
      <CuponsList refreshData={refreshData} />
    </>
  );
}

export default Cupons;
