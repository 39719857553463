import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import Loading from "../Loading";

import api from "../../services/api";

import CurrencyInput from "react-currency-input";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert2";

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from "components/Grid/GridItem.js";
import Table from "@material-ui/core/Table";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

import TableBody from "@material-ui/core/TableBody";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import PhotoCamera from "@material-ui/icons/PhotoCamera";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import noImage from "assets/img/no-image.png";

import CircularProgress from "@material-ui/core/CircularProgress";


import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/views/darkDashboardStyle.js";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function CriarCategorias() {
  const dark = false;
  const store = useSelector((state) => state);
  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);
  const classes = useStyles();

  const [categoria, setCategoria] = useState("");
  const [imagemCategoria, setImagemCategoria] = useState("Escolha uma imagem");
  const [showOrder, setShowOrder] = useState(0);

  const [subCategoria, setSubCategoria] = useState("");

  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);

  const [idCategoria, setIdCategoria] = useState();

  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
  const [subCategoriaSelecionada, setSubCategoriaSelecionada] = useState(null);

  const [imagemNome, setImagemNome] = useState("Escolha uma imagem");
  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  const [open, setOpen] = useState(false);
  const [openEditCategoria, setOpenEditCategoria] = useState(false);
  const [openEditSubCategoria, setOpenEditSubCategoria] = useState(false);

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);

  const [categoriaSelecionadaEdit, setCategoriaSelecionadaEdit] = useState([]);
  const [nomeCategoriaEdit, setNomeCategoriaEdit] = useState();
  const [showOrderCategoriaEdit, setShowOrderCategoriaEdit] = useState();
  const [imagemCategoriaEdit, setImagemCategoriaEdit] = useState();
  const [
    imagemCategoriaEditPreviewUrl,
    setImagemCategoriaEditPreviewUrl,
  ] = useState();

  const [subCategoriaSelecionadaEdit, setSubategoriaSelecionadaEdit] = useState(
    []
  );
  const [nomeSubCategoriaEdit, setNomeSubCategoriaEdit] = useState();
  const [
    subCategoriaIdCategoriaEdit,
    setSubCategoriaIdCategoriaEdit,
  ] = useState();

  const [loadingImage, setLoadingImage] = useState(false)

  const token = localStorage.getItem("token");

  useEffect(() => {
    setLoading(true);
    async function loadCategorias() {
      await api
        .get(
          "cardapio",
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          setLoading(false);
          setCategorias(response.data.categorias);
          setSubCategorias(response.data.sub_categorias);
        })
        .catch(() => {
          setLoading(false);

          swal
            .fire({
              html:
                "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              //  timer: 5000
            })
            .then(() => {
              // window.location.reload();
            });
          // setTimeout(() => window.location.reload(), 5000);
        });
    }

    loadCategorias();
  }, [refresh]);

  const changeFile = async (e) => {
    setLoadingImage(true)
    let reader = new FileReader();
    reader.onload = (e) => {
      console.log('loaded')
      setLoadingImage(false)
    }
    reader.onprogress = (e) => {
      console.log('onprogress')

    }


    reader.readAsArrayBuffer(e.target.files[0])

    setImagemCategoria(e.target.files[0]);
    setImagemNome(e.target.files[0].name);

    setImagePreviewUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleInsereCategoria = () => {
    setLoading(true);
    if (categoria !== "") {
      const data = {
        categoria: categoria,
      };
      api
        .post(
          "insere-categoria",
          data,
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          if (response.data) {
            setCategoria("");

            if (imagemCategoria !== "") {
              const id_categoria = response.data.id;
              const formData = new FormData();
              formData.append("image", imagemCategoria);
              const res = await api
                .post(`imagem-categoria/${id_categoria}`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then(async (response) => {
                  setLoading(false);
                  setRefresh(refresh + 1);
                  swal.fire({
                    html: "<h4>Categoria cadastrada com sucesso!</h4>",
                    icon: "success",
                    confirmButtonColor: "#2d3035",
                    confirmButtonText: "Ok",
                    timer: 6000,
                  });
                  setImagemCategoria();
                  setImagemNome("Escolha uma Imagem");
                  setImagePreviewUrl()
                })
                .catch((error) => {
                  setLoading(false);
                  if (error.response.status == 400) {
                    swal.fire({
                      html:
                        "<h4>Tipo de arquivo inválido</h4><br /><h5>Por favor tente novamente</h5>",
                      icon: "error",
                      confirmButtonColor: "#2d3035",
                      confirmButtonText: "Ok",
                      //  timer: 5000
                    });
                  } else {
                    swal.fire({
                      html:
                        "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
                      icon: "warning",
                      confirmButtonColor: "#2d3035",
                      confirmButtonText: "Ok",
                      //  timer: 5000
                    });
                  }
                });
            } else {
              setLoading(false);
              setRefresh(refresh + 1);
              swal.fire({
                html: "<h4>Categoria cadastrado com sucesso!</h4>",
                icon: "success",
                confirmButtonColor: "#2d3035",
                confirmButtonText: "Ok",
                timer: 6000,
              });
            }
          }
        });
    }
  };

  const selelecionaIdCategoria = () => {
    if (subCategoria !== "") {
      setOpen(true);
    } else {
      swal.fire({
        html: "<h4>Por favor digite um nome para a SubCategoria.</h4>",
        icon: "warning",
        confirmButtonColor: "#2d3035",
        confirmButtonText: "Ok",
        timer: 6000,
      });
    }
  };

  const handleInsereSubCategoria = () => {
    setLoading(true);
    const data = {
      sub_categoria: subCategoria,
      id_categoria: idCategoria,
    };
    if (idCategoria) {
      api
        .post(
          "insere-subcategoria",
          data,
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          setLoading(false);
          setOpen(false);
          setSubCategoria("");
          setIdCategoria();
          setRefresh(refresh + 1);
          swal.fire({
            html: "<h4>SubCategoria foi cadastrada com sucesso.</h4>",
            icon: "success",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 6000,
          });
        });
    } else {
      setLoading(false);
      swal.fire({
        html: "<h4>Por favor selecione uma categoria.</h4>",
        icon: "warning",
        confirmButtonColor: "#2d3035",
        confirmButtonText: "Ok",
        timer: 6000,
      });
    }
  };

  const handleEditCategoria = (categoria) => {
    setOpenEditCategoria(true);
    setCategoriaSelecionadaEdit(categoria);
  };
  const handleEditSubCategoria = (subCategoria) => {
    setOpenEditSubCategoria(true);
    setSubategoriaSelecionadaEdit(subCategoria);
  };

  const changeFileCategoriaEdit = async (e) => {
    let reader = new FileReader();
    setImagemCategoriaEdit(e.target.files[0]);

    setImagemCategoriaEditPreviewUrl(URL.createObjectURL(e.target.files[0]));

    // const formData = new FormData();
    // formData.append("image", e.target.files[0]);
    // const res = await api.put("/teste-imagem", formData, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // });
    // console.log(res);
  };

  const handleAtualizaCategoria = async () => {
    setLoading(true);
    setOpenEditCategoria(false);
    await api
      .put(
        `atualiza-categoria/${categoriaSelecionadaEdit.id}`,
        {
          categoria: nomeCategoriaEdit,
          show_order: showOrderCategoriaEdit,
        },
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async () => {
        const formData = new FormData();
        formData.append("image", imagemCategoriaEdit);
        const res = await api
          .post(`imagem-categoria/${categoriaSelecionadaEdit.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          })
          .then(async (response) => {
            setLoading(false);
            setRefresh(refresh + 1);
            swal.fire({
              html: "<h4>Categoria atualizada com sucesso!</h4>",
              icon: "success",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              timer: 6000,
            });
            setImagemCategoriaEdit();
            // setCategoriaSelecionadaEdit();
            setShowOrderCategoriaEdit();
            setRefresh(refresh + 1);
          });
      })
      .catch(() => {
        setLoading(false);

        swal.fire({
          html:
            "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
          icon: "warning",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",
          //  timer: 5000
        });
      });
  };

  const handleAtualizaSubCategoria = async () => {
    setLoading(true);
    setOpenEditSubCategoria(false);
    const data = {
      sub_categoria: nomeSubCategoriaEdit,
      id_categoria: subCategoriaIdCategoriaEdit,
    };

    api
      .put(
        `atualiza-subcategoria/${subCategoriaSelecionadaEdit.id}`,
        data,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        setLoading(false);
        swal.fire({
          html: "<h4>SubCategoria atualizada com sucesso.</h4>",
          icon: "success",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",
          timer: 6000,
        });
        setRefresh(refresh + 1);
      })
      .catch(() => {
        setLoading(false);

        swal.fire({
          html:
            "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
          icon: "warning",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",
          //  timer: 5000
        });
      });
  };

  const handleDeleteCategoria = async (idCategoria) => {
    swal
      .fire({
        html:
          "<h4>Deseja realmente excluir a categoria ?</h4><br /><h6>Todos os produtos e SubCaterias associados a esta categoria também serão excluídos</h6>",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#2d3035",
        confirmButtonColor: "#2d3035",
        confirmButtonText: "Excluir",
        //  timer: 5000
      })
      .then(async (result) => {
        setLoading(true);
        if (result.value) {
          api
            .delete(
              `exclui-categoria/${idCategoria}`,
              { headers: { Authorization: `Bearer ${token}` } },
              { timeout: 10000 }
            )
            .then(async (response) => {
              api
                .delete(
                  `delete-imagem-categoria/${idCategoria}`,
                  { headers: { Authorization: `Bearer ${token}` } },
                  { timeout: 10000 }
                ).then(async (response) => {
                  setLoading(false);
                  swal.fire({
                    html: "<h4>Categoria excluída</h4>",
                    icon: "error",
                    confirmButtonColor: "#2d3035",
                    confirmButtonText: "Ok",
                    timer: 6000,
                  });
                  setRefresh(refresh + 1);
                })
            })
            .catch(() => {
              setLoading(false);

              swal.fire({
                html:
                  "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
                icon: "warning",
                confirmButtonColor: "#2d3035",
                confirmButtonText: "Ok",
                //  timer: 5000
              });
            });
        }
      });
  };

  const handleDeleteSubCategoria = async (idSubCategoria) => {
    swal
      .fire({
        html:
          "<h4>Deseja realmente excluir a subCategoria ?</h4><br /><h6>Todos os produtos associados a esta subCategoria também serão excluídos</h6>",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#2d3035",
        confirmButtonColor: "#2d3035",
        confirmButtonText: "Excluir",
        //  timer: 5000
      })
      .then(async (result) => {
        setLoading(true);
        if (result.value) {
          api
            .delete(
              `exclui-subcategoria/${idSubCategoria}`,
              { headers: { Authorization: `Bearer ${token}` } },
              { timeout: 10000 }
            )
            .then(async (response) => {
              setLoading(false);
              swal.fire({
                html: "<h4>SubCategoria excluída</h4>",
                icon: "error",
                confirmButtonColor: "#2d3035",
                confirmButtonText: "Ok",
                timer: 6000,
              });
              setRefresh(refresh + 1);
            })
            .catch(() => {
              setLoading(false);

              swal.fire({
                html:
                  "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
                icon: "warning",
                confirmButtonColor: "#2d3035",
                confirmButtonText: "Ok",
                //  timer: 5000
              });
            });
        }
      });
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEditCategoria(false);
    setOpenEditSubCategoria(false);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Grid xs={12} sm={12} md={12} className={classes.center}>
            <br />
            <Typography variant="h6" className={classes.cardTitle}>
              Criar Categorias e SubCategorias
            </Typography>
          </Grid>
          <br />

          <Grid container spacing={3} direction="row" justify="space-between">
            <Grid item md={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Criar Nova Categoria"
                  value={categoria}
                  onChange={(event) => setCategoria(event.target.value)}
                />
              </FormControl>
              <br />
              <br />
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id="raised-button-file2"
                onChange={changeFile}
                type="file"
              />
              <label htmlFor="raised-button-file2">
                <Button
                  className="uploadButton2"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  variant="contained"
                >
                  <PhotoCamera />
                </Button>
              </label>
              <small> &nbsp; {imagemNome ? imagemNome : ""}</small>
              <Button
                onClick={handleInsereCategoria}
                variant="contained"
                size="small"
                style={{ float: "right" }}

              // className={classes.cadastrarButton}
              >
                Cadastrar Categoria
              </Button>
              <br />
              {imagePreviewUrl ? (
                <Grid item md={12} style={{ paddingTop: 18, marginLeft: 8 }}>
                  {loadingImage ? (
                    <CircularProgress
                      variant="determinate"
                      value={0}
                      size={80}
                      className={classes.loading}
                    />
                  ) : (
                    <img src={imagePreviewUrl} className={classes.media} />
                  )}

                  <Button
                    variant="contained"
                    component="span"
                    color="default"
                    size="small"
                    className={classes.cardBodyDark}
                    style={{ float: "right", bottom: 0 }}
                    onClick={() => {
                      setImagemNome("Escolha uma imagem");
                      setImagemCategoria();
                      setImagePreviewUrl();
                    }}
                  >
                    Remover Imagem
                  </Button>
                </Grid>
              ) : (
                ""
              )}

              <br />
              <small>
                &nbsp; &nbsp;Recomendamos imagens com até 50kb para uma melhor
                perfomance do site *
              </small>
              <br />
              <small>
                &nbsp; &nbsp;Não nos responsabilizamos por imagens com direitos
                autorais **
              </small>
            </Grid>
            <Grid item md={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Criar Nova SubCategoria"
                  value={subCategoria}
                  onChange={(event) => setSubCategoria(event.target.value)}
                />
              </FormControl>

              <br />
              <br />
              <Button
                onClick={selelecionaIdCategoria}
                variant="contained"
                size="small"
                style={{ float: "right" }}
              // className={classes.cadastrarButton}
              // className={classes.cadastrarCategoriaButton}
              >
                Cadastrar SubCategoria
              </Button>
            </Grid>
          </Grid>

          <br />
          <br />
          <br />

          <Grid container direction="row" justify="space-between">
            <GridItem xs={12} sm={12} md={6}>
              <Card className={classes.cardTable1}>
                <CardHeader className={classes.cardTable}>
                  <h4 className={classes.cardTitleWhite}>Categorias</h4>
                </CardHeader>
                <CardBody className={classes.cardBody}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          ID
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Nome
                        </TableCell>

                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Ordem
                        </TableCell>

                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Editar
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Excluir
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {categorias.map((categoria) => (
                        <>
                          <TableRow
                            key={categoria.id}
                            className={classes.tableBodyRow}
                            onClick={() => { }}
                          >
                            <TableCell className={classes.tableCell}>
                              {categoria.id}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {categoria.categoria}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {categoria.show_order}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              <EditIcon
                                onClick={() => handleEditCategoria(categoria)}
                              />
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              <DeleteIcon
                                onClick={() =>
                                  handleDeleteCategoria(categoria.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <Card className={classes.cardTable1}>
                <CardHeader className={classes.cardTable}>
                  <h4 className={classes.cardTitleWhite}>SubCategorias</h4>
                </CardHeader>
                <CardBody className={classes.cardBody}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          ID
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Nome
                        </TableCell>

                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          ID Categoria
                        </TableCell>

                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Editar
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Excluir
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subCategorias.map((subCategoria) => (
                        <>
                          <TableRow
                            key={subCategoria.id}
                            className={classes.tableBodyRow}
                            onClick={() => { }}
                          >
                            <TableCell className={classes.tableCell}>
                              {subCategoria.id}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {subCategoria.sub_categoria}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {subCategoria.id_categoria}
                            </TableCell>

                            <TableCell
                              className={classes.tableCell}
                              onClick={() =>
                                handleEditSubCategoria(subCategoria)
                              }
                            >
                              <EditIcon />
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              <DeleteIcon
                                onClick={() =>
                                  handleDeleteSubCategoria(subCategoria.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>
        </div>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Cadastrar SubCategoria</DialogTitle>
        <br />

        <DialogContent>
          <DialogContentText>
            Selecione a que Categoria esta SubCategoria pertence
          </DialogContentText>
          <br />
          <br />
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth
            onChange={(e) => setIdCategoria(e.target.value)}
          >
            {categorias.map((categoria) => (
              <MenuItem value={categoria.id}>{categoria.categoria}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <br />
        <br />
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleInsereSubCategoria}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditCategoria}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Editar Categoria</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <FormControl className={classes.formControl}>
              <TextField
                label="Nome Categoria"
                defaultValue={categoriaSelecionadaEdit.categoria}
                onChange={(event) => setNomeCategoriaEdit(event.target.value)}
              />
            </FormControl>
            <br />
            <FormControl className={classes.formControl}>
              <TextField
                label="Ordem"
                defaultValue={categoriaSelecionadaEdit.show_order}
                onChange={(event) =>
                  setShowOrderCategoriaEdit(event.target.value)
                }
                type="number"
              />
            </FormControl>
            <br />
            <br />
            <input
              accept="image/*"
              className={classes.input}
              style={{ display: "none" }}
              id="raised-button-file3"
              onChange={changeFileCategoriaEdit}
              type="file"
            />
            <label htmlFor="raised-button-file3">
              {imagemCategoriaEditPreviewUrl ? (
                <img
                  src={imagemCategoriaEditPreviewUrl}
                  className={classes.media}
                />
              ) : (
                <img
                  src={
                    categoriaSelecionadaEdit.imagem
                      ? categoriaSelecionadaEdit.imagem
                      : noImage
                  }
                  className={classes.media}
                />
              )}
            </label>
          </DialogContentText>
        </DialogContent>
        <br />
        <br />
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleAtualizaCategoria}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditSubCategoria}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Editar SubCategoria</DialogTitle>
        <br />

        <DialogContent>
          <DialogContentText>
            <FormControl className={classes.formControl}>
              <TextField
                label="Nome SubCategoria"
                defaultValue={subCategoriaSelecionadaEdit.sub_categoria}
                onChange={(event) =>
                  setNomeSubCategoriaEdit(event.target.value)
                }
              />
            </FormControl>
            <br />
          </DialogContentText>
          <br />
          <br />
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth
            onChange={(e) => setSubCategoriaIdCategoriaEdit(e.target.value)}
          >
            {categorias.map((categoria) => (
              <MenuItem value={categoria.id}>{categoria.categoria}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <br />
        <br />
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleAtualizaSubCategoria}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
