import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import api from "../../services/api";

import Loading from "../Loading";

import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert2";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";

import ChartistGraph from "react-chartist";

import GridItem from "components/Grid/GridItem.js";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

import Typography from "@material-ui/core/Typography";

import AccessTime from "@material-ui/icons/AccessTime";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DoneAllIcon from "@material-ui/icons/DoneAll";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/views/darkDashboardStyle.js";

var delays2 = 500,
  durations2 = 800;

export default function Relatorios() {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);

  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [diasRelatorio, setDiasRelatorio] = useState([]);
  const [diasRelatorioPedidos, setDiasRelatorioPedidos] = useState([]);

  const [selectedInitialDate, setInitialDate] = useState(null);
  const [selectedEndDate, setEndDate] = useState(null);

  const [filtraPeriodo, setFiltraPeriodo] = useState(false);
  const [dataPeriodo, setDataPeriodo] = useState();

  const token = localStorage.getItem("token");

  const [
    valorTotalPedidosFinalizados,
    setValorTotalPedidosFinalizados,
  ] = useState(0);

  const [totalPedidosFinalizados, setTotalPedidosFinalizados] = useState(0);

  const [
    totalPedidosFinalizados15Dias,
    setTotalPedidosFinalizados15Dias,
  ] = useState(0);

  const [
    valorTotalPedidosFinalizados15Dias,
    setValorTotalPedidosFinalizados15Dias,
  ] = useState(0);

  const [dark, setDark] = useState(false);
  const yoo = [
    diasRelatorioPedidos.map((pedido) => parseFloat(pedido.pedidos)),
  ];

  const yoo2 = [diasRelatorio.map((dia) => dia.date)];
  const maxPedidos = Math.max.apply(null, yoo[0]);
  // console.log(maxPedidos);

  const pedidosChart = {
    data: {
      labels: yoo2[0],
      series: [yoo[0]],
    },
    options: {
      low: 0,
      high: parseFloat(maxPedidos) + 20,

      axisX: {
        showGrid: false,
        onlyInteger: true,
      },

      chartPadding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 0,
      },
    },
    responsiveOptions: [
      [
        "screen and (max-width: 640px)",
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function(value) {
              return value[0];
            },
          },
        },
      ],
    ],
    animation: {
      draw: function(data) {
        if (data.type === "bar") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: "ease",
            },
          });
        }
      },
    },
  };

  useEffect(() => {
    async function buscaDados() {
      api
        .get(
          "relatorio",
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then((response) => {
          setTimeout(() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }, 5000);

          setLoading(false);
          // console.log(response.data);
          setDiasRelatorio(response.data.dataArraySeteDias);
          setDiasRelatorioPedidos(response.data.dataArraySeteDiasPedidos);

          setValorTotalPedidosFinalizados(
            response.data.valorTotalPedidosFinalizados
          );

          setTotalPedidosFinalizados(response.data.totalPedidosFinalizados);

          setTotalPedidosFinalizados15Dias(
            response.data.totalPedidosFinalizados15Dias
          );

          setValorTotalPedidosFinalizados15Dias(
            response.data.valorTotalPedidosFinalizados15Dias
          );
        })

        .catch(() => {
          setLoading(false);
          swal
            .fire({
              html:
                "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              timer: 6000,
            })
            .then(() => {
              // window.location.reload();
            });
          // setTimeout(() => window.location.reload(), 5000);
        });
    }

    buscaDados();
  }, []);

  function handleInitialDateChange(initialDate) {
    setInitialDate(initialDate);
    // setEndDate(moment(initialDate));
  }

  async function handleEndDateChange(endDate) {
    setEndDate(endDate);
    setLoading(true);
    setTimeout(() => {
      filtraPorData(endDate);
    }, 888);
  }

  async function filtraPorData(endDate) {
    await api
      .post(
        "relatorio-periodo",
        { selectedInitialDate, selectedEndDate: endDate },
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then((response) => {
        setLoading(false);
        setDataPeriodo(response.data);
        console.log(response.data);
        setFiltraPeriodo(true);
      })

      .catch(() => {
        setLoading(false);
        swal
          .fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 6000,
          })
          .then(() => {
            // window.location.reload();
          });
        // setTimeout(() => window.location.reload(), 5000);
      });
  }

  const formato = {
    minimumFractionDigits: 2,
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <GridContainer alignItems="center">
            <Typography variant="h4" className={classes.titleRelatorios}>
              Relatórios
            </Typography>
          </GridContainer>

          <br />
          <GridContainer
            alignItems="center"
            className={classes.createCupomTitle}
          >
            <Grid xs={12} sm={12} md={12} className={classes.center}>
              <br />
              <br />
              <Typography variant="h6" className={classes.cardTitle}>
                <small> Filtar por período</small>
              </Typography>
            </Grid>
            <br />
          </GridContainer>
          <GridContainer spacing={3}>
            <Grid container spacing={3} className={classes.formCupom}>
              <Grid item sm={6}>
                <DatePicker
                  autoOk
                  label="Data Ínicio"
                  value={selectedInitialDate}
                  locale="pt-br"
                  format="DD/MM/YYYY"
                  maxDate={moment()}
                  cancelLabel="Cancelar"
                  onChange={(e) => handleInitialDateChange(e)}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6}>
                <DatePicker
                  autoOk
                  label="Data Final"
                  locale="pt-br"
                  format="DD/MM/YYYY"
                  minDate={selectedInitialDate}
                  cancelLabel="Cancelar"
                  value={selectedEndDate}
                  onChange={(e) => handleEndDateChange(e)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </GridContainer>
          <br />
          <br />
          <br />

          {filtraPeriodo ? (
            <>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} xl={3}>
                  <Card className={classes.cardBody}>
                    <CardHeader stats icon>
                      <CardIcon color="danger">
                        <DoneAllIcon />
                      </CardIcon>

                      <h3 className={classes.cardTitle}>
                        {dataPeriodo.totalPedidos}
                        <br />
                      </h3>
                    </CardHeader>
                    <CardFooter stat>
                      <div className={classes.stats}>
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          <p className={classes.cardCategory}>
                            Total de pedidos finalizados
                          </p>
                          <p className={classes.cardCategory}>
                            {" "}
                            {moment(selectedInitialDate).format(
                              "DD/MM/YYYY"
                            )} - {moment(selectedEndDate).format("DD/MM/YYYY")}
                          </p>
                        </a>
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} xl={3}>
                  <Card className={classes.cardBody}>
                    <CardHeader stats icon>
                      <CardIcon color="danger">
                        <AttachMoneyIcon />
                      </CardIcon>

                      <h3 className={classes.cardTitle}>
                        {dataPeriodo.valorTotalVendido
                          ? `R$  
                        ${parseFloat(
                          dataPeriodo.valorTotalVendido
                        ).toLocaleString("pt-BR", formato)}`
                          : "R$ 0,00"}
                        <br />
                      </h3>
                    </CardHeader>
                    <CardFooter stat>
                      <div className={classes.stats}>
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          <p className={classes.cardCategory}>
                            Valor total dos pedidos finalizados
                          </p>
                          <p className={classes.cardCategory}>
                            {moment(selectedInitialDate).format("DD/MM/YYYY")} -{" "}
                            {moment(selectedEndDate).format("DD/MM/YYYY")}
                          </p>
                        </a>
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
              </GridContainer>
              <br />
              <br />
              <div className={classes.hr} />
              <br />
              <br />
            </>
          ) : (
            ""
          )}


          <br />


          <br />
          <GridItem xs={12} sm={12} md={12}>
            <Card chart className={classes.cardBody}>
              <CardHeader>
                <ChartistGraph
                  className="ct-chart"
                  data={pedidosChart.data}
                  type="Line"
                  options={pedidosChart.options}
                  listener={pedidosChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Leituras </h4>
                {/* <p className={classes.cardCategory}>Últimos 15 dias</p> */}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> Últimos 7 dias
                </div>
              </CardFooter>
            </Card>
            <Grid item>
                  <h2 style={{ color: "#919192" }}>Replicar por tanques?:</h2>
                </Grid>
          </GridItem>

          <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: "calc(100vh - 350px)" }}
              >
          
              </Grid>
        </>
      )}
    </>
  );
}
