import React, { useState, useEffect } from "react";
// import { Redirect } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert2";
import moment from "moment";

import { Offline, Online } from "react-detect-offline";

import { SmileOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import yoodemoSocket from "services/webSocket";

import CurrencyInput from "react-currency-input";

import ComputerIcon from "@material-ui/icons/Computer";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
// import RestaurantIcon from "@material-ui/icons/Restaurant";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PrintIcon from "@material-ui/icons/Print";
// core components
import GridItem from "components/Grid/GridItem.js";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";

import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
// import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import TextField from "@material-ui/core/TextField";

import InputLabel from "@material-ui/core/InputLabel";

// import { Fade } from "@material-ui/core";
import { Grow } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/views/darkDashboardStyle.js";
import api from "../../services/api";
import Loading from "../Loading";

export default function Dashboard() {
  const dark = useSelector((state) => state.dark);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);

  let wentOffline;
  let wentOnline;
  function handleConnectionChange(event) {
    if (event.type == "offline") {
      // alert("Conexão perdida");
      swal.fire({
        html:
          "<h4>Você perdeu sua conexão com a internet</h4><br /><h5>Algumas funções não estão disponíveis</h5>",
        icon: "warning",
        confirmButtonColor: "#2d3035",
        confirmButtonText: "Ok",
        timer: 5000,
      });
    }
    if (event.type == "online") {
      console.log(" Voltou a conexão");
      wentOnline = new Date(event.timeStamp);
    }
  }
  window.addEventListener("online", handleConnectionChange);
  window.addEventListener("offline", handleConnectionChange);

  const [pedidos, setPedidos] = useState([]);
  const [idPedido, setIdPedido] = useState("");
  const [detalhesPedido, setDetalhesPedido] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [produtosPedido, setProdutosPedido] = useState([]);
  const [enderecoEntrega, setEnderecoEntrega] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [buscaPedidos, setBuscaPedidos] = useState(false);

  const [openFinalizaDialog, setOpenFinalizaDialog] = useState(false);

  const [valueFieldDinheiro, setValueFieldDinheiro] = useState("0");
  const [valueFieldDebito, setValueFieldDebito] = useState("0");
  const [valueFieldCredito, setValueFieldCredito] = useState("0");

  const [loading, setLoading] = useState(true);

  const [totalPedidosFinalizados, setTotalPedidosFechados] = useState(0);
  const [valorTotalPedidosFinalizados, setValorTotalPedidosFechados] = useState(
    0
  );

  const token = localStorage.getItem("token");

  const [usersOnline, setUsersOnline] = useState(0);

  const [printPedido, setPrintPedido] = useState(false);

  const formatter = new Intl.NumberFormat("en-US", {});

  useEffect(() => {
    setBuscaPedidos(false);
    // console.log("load novos pedidos");

    async function loadPedidos() {
      await api
        .get(
          "busca-pedidos-abertos",
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          //console.log(response.data);
          setLoading(false);
          setPedidos(response.data.pedidos);
          setTotalPedidosFechados(response.data.totalPedidosFinalizados);
          setValorTotalPedidosFechados(
            parseFloat(response.data.valorTotalPedidosFinalizados)
          );
          var countfiltered = response.data.pedidos.filter(function (element) {
            return element.status == "pedido_online";
          }).length;
          dispatch({ type: "PEDIDOS_NAO_ACEITOS", number: countfiltered });
        })
        .catch(() => {
          setLoading(false);
          setBuscaPedidos(false);
          swal
            .fire({
              html:
                "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              //  timer: 5000
            })
            .then(() => {
              // window.location.reload();
            });
          // setTimeout(() => window.location.reload(), 5000);
        });
    }

    loadPedidos();

    const socket =
      yoodemoSocket.getSubscription('japa') ||
      yoodemoSocket.subscribe('japa');

    const user_id = localStorage.getItem("user_id");

    socket.on("novoPedidoAberto", () => {
      // falaPedido();
      // openNotification();
      setTimeout(() => {
        loadPedidos();
      }, 300);
    });
    socket.on("novoStatusPedido", () => {
      // falaPedido();
      // openNotification();
      setTimeout(() => {
        loadPedidos();
      }, 300);
    });

    socket.on("usersOnline", (data) => {
      // console.log(data);
      if (data > 0) {
        setUsersOnline(data);
      } else {
        setUsersOnline(0);
      }
    });
  }, []);

  async function loadPedidosNovos() {
    await api
      .get(
        "busca-pedidos-abertos",
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        // console.log("load novos pedidos");
        setLoading(false);
        setPedidos(response.data.pedidos);
        setTotalPedidosFechados(response.data.totalPedidosFinalizados);
        setValorTotalPedidosFechados(
          parseFloat(response.data.valorTotalPedidosFinalizados)
        );
        var countfiltered = response.data.pedidos.filter(function (element) {
          return element.status == "pedido_online";
        }).length;
        dispatch({ type: "PEDIDOS_NAO_ACEITOS", number: countfiltered });
      })

      .catch(() => {
        setLoading(false);
        // loadPedidosNovos();
        swal
          .fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            //  timer: 5000
          })
          .then(() => {
            window.location.reload();
          });
        // setTimeout(() => window.location.reload(), 5000);
      });
  }

  const classes = useStyles();

  const formato = {
    minimumFractionDigits: 2,
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenFinalizaDialog(false);
    setValueFieldDinheiro("0");
    setValueFieldDebito("0");
    setValueFieldCredito("0");
  };

  async function handleClickPedido(id_pedido) {
    // alert(id_pedido);

    setIdPedido(id_pedido);
    setLoading(true);
    await api
      .get(
        `busca-pedido/${id_pedido}`,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        // console.log(response.data[0]);
        setDetalhesPedido(response.data[0]);
        setCliente(response.data[0].cliente);
        setProdutosPedido(response.data[0].produtosPedido);
        setEnderecoEntrega(response.data[0].cliente.enderecos[0]);

        setOpenDialog(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        swal
          .fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 5000,
          })
          .then(() => {
            // window.location.reload();
          });
        // setTimeout(() => window.location.reload(), 5000);
      });
  }

  async function handlePrintPedido(id_pedido) {
    // alert(id_pedido);

    const socket =
      yoodemoSocket.getSubscription('japa') ||
      yoodemoSocket.subscribe('japa');

    socket.emit("imprimePedidoSistema", {
      id_pedido,
    });
  }

  async function handleClickFinalizaPedido(id_pedido) {
    setLoading(true);
    await api
      .get(
        `busca-pedido/${id_pedido}`,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        // console.log(response.data[0]);
        setDetalhesPedido(response.data[0]);
        setCliente(response.data[0].cliente);
        setProdutosPedido(response.data[0].produtosPedido);
        setEnderecoEntrega(response.data[0].cliente.enderecos[0]);

        setOpenFinalizaDialog(true);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        swal
          .fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 6000,
          })
          .then(() => {
            // window.location.reload();
          });
        // setTimeout(() => window.location.reload(), 5000);
      });
  }

  async function alteraStatusPedido(id_pedido, id_cliente, status) {
    setOpenDialog(false);
    setLoading(true);

    const token = localStorage.getItem("token");
    const data_pedido = {
      id_pedido,
      id_cliente,
      status,
      type: "admin",
    };
    await api
      .put(
        `altera-status-pedido/${id_pedido}`,
        data_pedido,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then((response) => {
        setLoading(false);
        if (status === "pedido_aberto") {
          swal.fire({
            html: "<h4>Pedido aceito</h4>",
            icon: "success",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 2500,
          });
        } else if (status === "pedido_transito") {
          swal.fire({
            html: "<h4>Pedido em Trânsito</h4>",
            icon: "success",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 2500,
          });
        }

        const socket =
          yoodemoSocket.getSubscription('japa') ||
          yoodemoSocket.subscribe('japa');

        socket.emit("mudaStatusPedido", {
          data_pedido,
        });
        loadPedidosNovos();
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        loadPedidosNovos();
      });
  }

  function handleClickPagamento(event) {
    const dinheiroFormatado = valueFieldDinheiro.toString().replace(",", ".");
    const debitoFormatado = valueFieldDebito.toString().replace(",", ".");
    const creditoFormatado = valueFieldCredito.toString().replace(",", ".");

    if (event.name === "valor-dinheiro") {
      const value =
        detalhesPedido.valor_total -
        parseFloat(debitoFormatado).toFixed(2) -
        parseFloat(creditoFormatado).toFixed(2);
      if (value >= 0) {
        setValueFieldDinheiro(value.toLocaleString("pt-BR", formato));
      } else {
        setValueFieldDinheiro("0,00");
      }
    }
    if (event.name === "valor-debito") {
      const value =
        detalhesPedido.valor_total -
        parseFloat(dinheiroFormatado).toFixed(2) -
        parseFloat(creditoFormatado).toFixed(2);
      if (value >= 0) {
        setValueFieldDebito(value.toLocaleString("pt-BR", formato));
      } else {
        setValueFieldDebito("0,00");
      }
    }
    if (event.name === "valor-credito") {
      const value =
        detalhesPedido.valor_total -
        parseFloat(debitoFormatado).toFixed(2) -
        parseFloat(dinheiroFormatado).toFixed(2);
      if (value >= 0) {
        setValueFieldCredito(value.toLocaleString("pt-BR", formato));
      } else {
        setValueFieldCredito("0,00");
      }
    }
  }

  async function insereRecebimento() {
    setOpenFinalizaDialog(false);
    const dinheiroFormatado = valueFieldDinheiro.toString().replace(",", ".");
    const debitoFormatado = valueFieldDebito.toString().replace(",", ".");
    const creditoFormatado = valueFieldCredito.toString().replace(",", ".");

    const valor_recebimento_total =
      parseFloat(dinheiroFormatado) +
      parseFloat(debitoFormatado) +
      parseFloat(creditoFormatado);

    let dinheiro = null;
    let debito = null;
    let credito = null;

    const data = [];

    if (valueFieldDinheiro != 0) {
      dinheiro = {
        tipo_pagamento: "Dinheiro",
        id_pedido: detalhesPedido.id_pedido,
        valor: parseFloat(dinheiroFormatado).toFixed(2),
      };
      data.push(dinheiro);
    }
    if (valueFieldDebito != 0) {
      debito = {
        tipo_pagamento: "Débito",
        id_pedido: detalhesPedido.id_pedido,
        valor: parseFloat(debitoFormatado).toFixed(2),
      };
      data.push(debito);
    }
    if (valueFieldCredito != 0) {
      credito = {
        tipo_pagamento: "Crédito",
        id_pedido: detalhesPedido.id_pedido,
        valor: parseFloat(creditoFormatado).toFixed(2),
      };
      data.push(credito);
    }

    swal
      .fire({
        html: `<h4>Registrar pagamento</h4><br /><h5>Total: R$ ${valor_recebimento_total.toLocaleString(
          "pt-BR",
          formato
        )}</h5>`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#2d3035",
        confirmButtonColor: "#2d3035",
        confirmButtonText: "Ok",
        timer: 15000,
      })
      .then(async (result) => {
        if (result.value) {
          api
            .post(
              "insere-recebimento",
              { data },
              { headers: { Authorization: `Bearer ${token}` } },
              { timeout: 10000 }
            )
            .then((response) => {
              swal.fire({
                html:
                  "<h4>Pagamento registrado</h4><br><h5>O pedido foi finalizado</h5>",
                icon: "success",
                confirmButtonColor: "#2d3035",
                confirmButtonText: "Ok",
                timer: 6500,
              });

              const data_pedido = {
                id_pedido: detalhesPedido.id_pedido,
                status: "finalizado",
                type: "admin",
              };
              const socket =
                yoodemoSocket.getSubscription(
                  'japa'
                ) || yoodemoSocket.subscribe('japa');

              socket.emit("mudaStatusPedido", {
                data_pedido,
              });

              loadPedidosNovos();
              setValueFieldDinheiro(0);
              setValueFieldDebito(0);
              setValueFieldCredito(0);
            });
        } else {
          setValueFieldDinheiro(0);
          setValueFieldDebito(0);
          setValueFieldCredito(0);
        }
      });
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <br />
          <div>

          </div>
          <div>
            <br />
            <div className={classes.hr} />
            <br />
            {/* <Offline>
          <h1>Você está Offline</h1>
        </Offline> */}
            {pedidos.length > 0 ? (
              <GridContainer>
                {pedidos.map((pedido) => (
                  <>
                    <GridItem xs={12} sm={6} md={4} xl={3} key={pedido.id}>
                      <Card className={classes.cardBody}>
                        <CardHeader
                          stats
                          icon
                          onClick={() => {
                            handleClickPedido(pedido.id_pedido);
                          }}
                          className={classes.pointer}
                        >
                          {pedido.status === "pedido_aberto" ? (
                            <CardIcon
                              className={classes.abertoTitle}
                              color="warning"
                            >
                              <AssignmentTurnedInIcon />
                            </CardIcon>
                          ) : (
                            ""
                          )}

                          {pedido.status === "pedido_transito" ? (
                            <CardIcon
                              className={classes.transitoTilte}
                              color="success"
                            >
                              <MotorcycleIcon />
                            </CardIcon>
                          ) : (
                            ""
                          )}
                          {pedido.status === "pedido_online" ? (
                            <CardIcon
                              className={classes.onlineTilte}
                              color="primary"
                            >
                              <ComputerIcon />
                            </CardIcon>
                          ) : (
                            ""
                          )}
                          {pedido.status === "pedido_atrasado" ? (
                            <CardIcon color="danger">
                              <AccessAlarmIcon />
                            </CardIcon>
                          ) : (
                            ""
                          )}
                          <h3 className={classes.cardTitle}>
                            <small>Pedido N&ordm;</small> {pedido.id}
                            <br />
                          </h3>
                          <p
                            className={
                              dark ? classes.cardTitleDark : classes.cardTitle
                            }
                          >
                            Total: R${" "}
                            {parseFloat(pedido.valor_total).toLocaleString(
                              "pt-BR",
                              formato
                            )}
                          </p>
                        </CardHeader>
                        <CardFooter
                          stat
                          onClick={() => {
                            handleClickPedido(pedido.id_pedido);
                          }}
                          className={classes.pointer}
                        >
                          <div className={classes.stats}>
                            <a
                              href={pedido.id}
                              onClick={(e) => e.preventDefault()}
                            >
                              <p className={classes.cardCategory}>
                                {pedido.cliente.name}
                              </p>
                              <p className={classes.cardCategory}>
                                {pedido.cliente.telefone}
                              </p>
                              <p className={classes.cardCategory}>
                                {/* Horário: {pedido.hora} */}
                              </p>
                              <p className={classes.cardCategory}>
                                Horário:{" "}
                                {moment(pedido.horario).format(
                                  "DD/MM/YYYY - HH:mm"
                                )}
                              </p>
                              <p className={classes.cardCategory}>
                                Previsão:{" "}
                                {moment(pedido.horario_previsto).format(
                                  "DD/MM/YYYY - HH:mm"
                                )}
                              </p>
                            </a>
                          </div>
                        </CardFooter>
                        {pedido.status !== "pedido_online" ? (
                          <Grid
                            container
                            direction="row"
                            justify="space-around"
                            alignItems="stretch"
                            className={classes.cardButtons}
                          >
                            <Button
                              variant="contained"
                              style={{ background: "#22252a", color: "#fff" }}
                              onClick={() =>
                                handlePrintPedido(pedido.id_pedido)
                              }
                            >
                              <PrintIcon />
                            </Button>

                            {pedido.status !== "pedido_transito" ? (
                              <Button
                                variant="contained"
                                className={classes.transitoButton}
                                onClick={() => {
                                  alteraStatusPedido(
                                    pedido.id_pedido,
                                    pedido.id_cliente,
                                    "pedido_transito"
                                  );
                                }}
                              >
                                <MotorcycleIcon />
                              </Button>
                            ) : (
                              ""
                            )}
                            <Button
                              variant="contained"
                              className={classes.finalizarButton}
                              onClick={() => {
                                handleClickFinalizaPedido(pedido.id_pedido);
                              }}
                            >
                              Finalizar
                            </Button>
                          </Grid>
                        ) : (
                          <Grid
                            container
                            direction="row"
                            justify="space-around"
                            alignItems="stretch"
                            className={`${classes.cardButtons} ${classes.pointer}`}
                            onClick={() => {
                              handleClickPedido(pedido.id_pedido);
                            }}
                          >
                            <p>&nbsp;</p>
                          </Grid>
                        )}
                      </Card>
                    </GridItem>
                  </>
                ))}
              </GridContainer>
            ) : (
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: "calc(100vh - 350px)" }}
              >
                <Grid item>
                  <h1 style={{ color: "#919192" }}>Alguma Info ....</h1>
                </Grid>
              </Grid>
            )}
          </div>

          <Dialog
            fullScreen
            open={openDialog}
            onClose={handleClose}
            TransitionComponent={Grow}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                // color="#000"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.titleOrder}>
                  Pedido N&ordm; {detalhesPedido.id}
                </Typography>
                {detalhesPedido.status === "pedido_online" ? (
                  <>
                    <Button
                      color="inherit"
                      className={classes.rejectButton}
                      onClick={() => {
                        alteraStatusPedido(
                          detalhesPedido.id_pedido,
                          detalhesPedido.id_cliente,
                          "pedido_recusado"
                        );
                      }}
                    >
                      Recusar Pedido
                    </Button>
                    &nbsp; &nbsp; &nbsp;
                    <Button
                      color="inherit"
                      className={classes.acceptButton}
                      onClick={() => {
                        alteraStatusPedido(
                          detalhesPedido.id_pedido,
                          detalhesPedido.id_cliente,
                          "pedido_aberto"
                        );
                      }}
                    >
                      Aceitar Pedido
                    </Button>
                  </>
                ) : (
                  <Button
                    color="inherit"
                    className={classes.rejectButton}
                    onClick={() => {
                      alteraStatusPedido(
                        detalhesPedido.id_pedido,
                        detalhesPedido.id_cliente,
                        "pedido_excluido"
                      );
                    }}
                  >
                    Excluir Pedido
                  </Button>
                )}
              </Toolbar>
            </AppBar>
            <GridContainer spacing={5}>
              <Grid item xs={6} className={classes.orderContainer}>
                <List>
                  <ListItem button className={classes.ListItem}>
                    <ListItemText primary="Cliente" secondary={cliente.name} />
                  </ListItem>
                  <Divider />
                  <ListItem button className={classes.ListItem}>
                    <ListItemText
                      primary="Telefone"
                      secondary={cliente.telefone}
                      onClick={() => {
                        window.open(
                          `https://api.whatsapp.com/send?phone=55${cliente.telefone}&text=Olá, ${cliente.name}`,
                          "_blank"
                        );
                      }}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem button className={classes.ListItem}>
                    <ListItemText
                      primary="Horário"
                      secondary={moment(detalhesPedido.horario).format(
                        "DD/MM/YYYY - HH:mm"
                      )}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem button className={classes.ListItem}>
                    <ListItemText
                      primary="Observações do pedido"
                      secondary={detalhesPedido.observacoes_gerais}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem button className={classes.ListItem}>
                    <ListItemText
                      primary="Quantidade de Hashis"
                      secondary={detalhesPedido.qtd_hashis}
                    />
                  </ListItem>
                  <Divider />
                </List>
              </Grid>
              <Grid item xs={6} className={classes.orderContainer}>
                <List>
                  <ListItem button className={classes.ListItem}>
                    <ListItemText primary="Email" secondary={cliente.email} />
                  </ListItem>
                  <Divider />
                  <ListItem button className={classes.ListItem}>
                    <ListItemText
                      primary="Forma Pagamento"
                      secondary={detalhesPedido.forma_pagamento}
                    />
                  </ListItem>
                  <Divider />
                  {detalhesPedido.valor_troco ? (
                    <>
                      <ListItem button className={classes.ListItem}>
                        <ListItemText
                          primary="Troco para:"
                          secondary={`R$
                      ${parseFloat(detalhesPedido.valor_troco).toLocaleString(
                            "pt-BR",
                            formato
                          )} `}
                        />
                      </ListItem>
                      <Divider />
                    </>
                  ) : (
                    ""
                  )}
                  {detalhesPedido.endereco_entrega !== "Retirada Balcão" ? (
                    <>
                      <ListItem button className={classes.ListItem}>
                        <ListItemText
                          primary="Endereço"
                          secondary={` ${enderecoEntrega.logradouro},
                       ${enderecoEntrega.numero_casa} - 
                       ${enderecoEntrega.bairro}, 
                       ${enderecoEntrega.cep} `}
                        />
                      </ListItem>
                      <Divider />
                    </>
                  ) : (
                    <>
                      <ListItem button className={classes.ListItem}>
                        <ListItemText
                          primary="Retirada Balcão"
                          secondary="&nbsp;"
                        />
                      </ListItem>
                      <Divider />
                    </>
                  )}

                  {detalhesPedido.endereco_entrega !== "Retirada Balcão" ? (
                    <>
                      <ListItem button className={classes.ListItem}>
                        <ListItemText
                          primary="Taxa de Entrega"
                          secondary={`R$
                           ${parseFloat(
                            detalhesPedido.valor_entrega
                          ).toLocaleString("pt-BR", formato)} `}
                        />
                      </ListItem>
                    </>
                  ) : (
                    <>
                      <ListItem button className={classes.ListItem}>
                        <ListItemText
                          primary="Taxa de Entrega"
                          secondary="R$ 0,00 (Balcão)"
                        />
                      </ListItem>
                    </>
                  )}

                  <Divider />
                </List>
              </Grid>
              <br /> <br />
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title2}>
                  Produtos
                </Typography>
                <br /> <br />
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        Produto
                      </TableCell>
                      <TableCell />
                      <TableCell className={classes.tableCell} align="right">
                        R$
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {produtosPedido.map((produto) => (
                      <TableRow key={produto.id}>
                        <TableCell>
                          {produto.categoria === "Pizzas Salgadas" ||
                            produto.categoria === "Pizzas Doces" ? (
                            <>
                              <b>
                                {" "}
                                {produto.quantidade}x {produto.sub_categoria}
                              </b>
                              <br />
                              {produto.sabor3 ? (
                                <>
                                  <br />
                                  &#8531; {produto.sabor1}{" "}
                                  {produto.observacoes_sabor1 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Obs: {produto.observacoes_sabor1})
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {produto.adicionais_sabor1 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Adicionais:{" "}
                                        {produto.adicionais_sabor1
                                          .replace("{", "")
                                          .replace("}", "")
                                          .replace(" ", "")}
                                        )
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <br />
                                  <br />
                                  &#8531; {produto.sabor2}{" "}
                                  {produto.observacoes_sabor2 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Obs: {produto.observacoes_sabor2})
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {produto.adicionais_sabor2 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Adicionais:{" "}
                                        {produto.adicionais_sabor2
                                          .replace("{", "")
                                          .replace("}", "")
                                          .replace(" ", "")}
                                        )
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <br />
                                  <br />
                                  &#8531; {produto.sabor3}{" "}
                                  {produto.observacoes_sabor3 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Obs: {produto.observacoes_sabor3})
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {produto.adicionais_sabor3 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Adicionais:{" "}
                                        {produto.adicionais_sabor3
                                          .replace("{", "")
                                          .replace("}", "")
                                          .replace(" ", "")}
                                        )
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {produto.sabor2 && !produto.sabor3 ? (
                                <>
                                  <br />
                                  &frac12; {produto.sabor1}{" "}
                                  {produto.observacoes_sabor1 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Obs: {produto.observacoes_sabor1})
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {produto.adicionais_sabor1 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Adicionais:{" "}
                                        {produto.adicionais_sabor1
                                          .replace("{", "")
                                          .replace("}", "")
                                          .replace(" ", "")}
                                        )
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <br />
                                  <br />
                                  &frac12; {produto.sabor2}{" "}
                                  {produto.observacoes_sabor2 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Obs: {produto.observacoes_sabor2})
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {produto.adicionais_sabor2 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Adicionais:{" "}
                                        {produto.adicionais_sabor2
                                          .replace("{", "")
                                          .replace("}", "")
                                          .replace(" ", "")}
                                        )
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {!produto.sabor2 && !produto.sabor3 ? (
                                <>
                                  <br />
                                  {produto.sabor1}{" "}
                                  {produto.observacoes_sabor1 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Obs: {produto.observacoes_sabor1})
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {produto.adicionais_sabor1 ? (
                                    <>
                                      <br />
                                      <small>
                                        (Adicionais:{" "}
                                        {produto.adicionais_sabor1
                                          .replace("{", "")
                                          .replace("}", "")
                                          .replace(" ", "")}
                                        )
                                      </small>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              <br />
                              <br />
                              <small>
                                {produto.massa_pizza}{" "}
                                {produto.valor_massa_pizza
                                  ? `R$ ${parseFloat(
                                    produto.valor_massa_pizza
                                  )}`
                                  : ""}
                              </small>
                              <br />
                              <small>
                                {produto.borda_pizza}{" "}
                                {produto.valor_borda_pizza
                                  ? `R$ ${parseFloat(
                                    produto.valor_borda_pizza
                                  )}`
                                  : ""}
                              </small>
                            </>
                          ) : (
                            <>
                              <b>
                                {" "}
                                {produto.quantidade}x {produto.sub_categoria}
                              </b>
                              <br />
                              {produto.produto}
                              <br />
                              {produto.observacoes ? (
                                <small> {produto.observacoes}</small>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {/* <DeleteIcon
                            onClick={() => {
                              handleDelete(produto);
                            }}
                            size="small"
                            className={classes.redIcon}
                          /> */}
                        </TableCell>
                        <TableCell align="right">
                          {parseFloat(produto.valor_produto).toLocaleString(
                            "pt-BR",
                            formato
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    <br />
                    <br />
                    <TableRow>
                      <TableCell align="right" colSpan={2}>
                        <small>Subtotal</small>
                      </TableCell>
                      <TableCell align="right">
                        {parseFloat(detalhesPedido.sub_total).toLocaleString(
                          "pt-BR",
                          formato
                        )}
                      </TableCell>
                    </TableRow>

                    {detalhesPedido.cupom ? (
                      <TableRow>
                        <TableCell align="right" colSpan={2}>
                          <small>(Cupom: {detalhesPedido.cupom})</small>
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          -{" "}
                          {parseFloat(
                            detalhesPedido.valor_desconto
                          ).toLocaleString("pt-BR", formato)}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                    <TableRow>
                      <TableCell align="right" colSpan={2}>
                        <small>Entrega</small>
                      </TableCell>

                      <TableCell align="right">
                        {detalhesPedido.endereco_entrega == "Retirada Balcão"
                          ? "0,00"
                          : ` ${parseFloat(
                            detalhesPedido.valor_entrega
                          ).toLocaleString("pt-BR", formato)}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" colSpan={2}>
                        Total
                      </TableCell>
                      <TableCell align="right">
                        {parseFloat(detalhesPedido.valor_total).toLocaleString(
                          "pt-BR",
                          formato
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </GridContainer>
            <br />
            <br />
            <br />
          </Dialog>
        </>
      )}

      <Dialog
        // onClose={() => { }}
        aria-labelledby="simple-dialog-title"
        open={openFinalizaDialog}
        onClose={handleClose}
      >
        <DialogTitle id="simple-dialog-title">
          Finalizar pedido N&ordm; {detalhesPedido.id}
        </DialogTitle>
        <br />
        <Grid
          container
          direction="row"
          // spacing={2}
          className={classes.bodyDialogFinaliza}
        >
          <Grid item md={12} className={classes.fieldDialogFinaliza}>
            <Typography variant="h6">
              R${" "}
              {parseFloat(detalhesPedido.valor_total).toLocaleString(
                "pt-BR",
                formato
              )}
            </Typography>
          </Grid>
          <br /> <br /> <br />
          <Grid item md={4} className={classes.fieldDialogFinaliza}>
            <InputLabel>Dinheiro</InputLabel>
            <CurrencyInput
              className={classes.inputMoney2}
              decimalSeparator=","
              thousandSeparator=""
              name="valor-dinheiro"
              // prefix="R$ "
              value={valueFieldDinheiro}
              onClick={(event) => handleClickPagamento(event.target)}
              onChangeEvent={(event) =>
                setValueFieldDinheiro(event.target.value)
              }
            />
          </Grid>
          <Grid item md={4} className={classes.fieldDialogFinaliza}>
            <InputLabel>Débito</InputLabel>

            <CurrencyInput
              className={classes.inputMoney2}
              decimalSeparator=","
              thousandSeparator=""
              name="valor-debito"
              // prefix="R$ "
              value={valueFieldDebito}
              onClick={(event) => handleClickPagamento(event.target)}
              onChangeEvent={(event) => setValueFieldDebito(event.target.value)}
            />
          </Grid>
          <Grid item md={4} className={classes.fieldDialogFinaliza}>
            <InputLabel>Crédito</InputLabel>

            <CurrencyInput
              className={classes.inputMoney2}
              decimalSeparator=","
              thousandSeparator=""
              name="valor-credito"
              // prefix="R$ "
              value={valueFieldCredito}
              onClick={(event) => handleClickPagamento(event.target)}
              onChangeEvent={(event) =>
                setValueFieldCredito(event.target.value)
              }
            />
          </Grid>
        </Grid>

        <br />
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className={classes.finalizarButton}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            className={classes.finalizarButton}
            onClick={insereRecebimento}
            style={{ flex: "1 0 0" }}
          >
            Finalizar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
