import axios from "axios";

// const api = axios.create({
//   baseURL: 'https://api.n2delivery.com.br/oahu/sistema',
// });

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_LOCALHOST,
// });

export default api;
