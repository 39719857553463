import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import Loading from "../Loading";

import api from "../../services/api";

import cep from "cep-promise";


import CurrencyInput from "react-currency-input";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert2";

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from "components/Grid/GridItem.js";
import Table from "@material-ui/core/Table";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

import TableBody from "@material-ui/core/TableBody";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import PhotoCamera from "@material-ui/icons/PhotoCamera";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import InputMask from "react-input-mask";


import noImage from "assets/img/no-image.png";

import CircularProgress from "@material-ui/core/CircularProgress";


import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/views/darkDashboardStyle.js";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import ProductsList from "components/ProductsList";
import convertAddressToLatLng from "utils/convertAddressToLatLng";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function NewOrder() {
  const dark = false;
  const store = useSelector((state) => state);
  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);
  const classes = useStyles();


  const [clientName, setClientName] = useState('')
  const [clientPhone, setClientPhone] = useState('')
  const [clientEmail, setClientEmail] = useState('')
  const [clientCEP, setClientCEP] = useState('')
  const [clientLogradouro, setClientLogradouro] = useState('')

  const [clientHouseNumber, setClientHouseNumber] = useState('');
  const [clientNeighborhood, setClientNeighborhood] = useState('');
  const [clientComplement, setClientComplement] = useState('')
  const [clientCity, setClientCity] = useState('');
  const [clientState, setClientState] = useState('');

  const [clientLat, setClientLat] = useState()
  const [clientLng, setClientLng] = useState()

  const [clientDeliveryValue, setClientDeliveryValue] = useState("0.00")

  const [productsMenu, setProductsMenu] = useState([])

  const [produtos, setProdutos] = useState([]);

  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);

  const [passNumber, setPassNumber] = useState(1)

  const [areasEntrega, setAreasEntrega] = useState([]);

  const [clientAreaId, setClientAreaId] = useState(0);

  const [localOrder, setLocalOrder] = useState(false)

  const [loading, setLoading] = useState(false)

  const token = localStorage.getItem("token");


  useEffect(() => {
    async function loadProdutos() {
      await api
        .get(
          "cardapio",
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          console.log(response.data);
          setLoading(false);
          setCategorias(response.data.categorias);
          setSubCategorias(response.data.sub_categorias);
          setProdutos(response.data.produtos);
        })
        .catch(() => {
          setLoading(false);

          swal
            .fire({
              html:
                "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              //  timer: 5000
            })
            .then(() => {
              // window.location.reload();
            });
          // setTimeout(() => window.location.reload(), 5000);
        });
    }

    loadProdutos();
  }, []);

  function firstPass() {
    setPassNumber(1)

  }


  function secondPass() {
    setPassNumber(2)
  }

  const buscaCep = () => {
    cep(clientCEP).then((response) => {
      //console.log(response);
      setClientLogradouro(response.street);
      setClientNeighborhood(response.neighborhood);
      setClientCity(response.city);
      setClientState(response.state);

      setTimeout(() => {
        document.getElementById("numero").focus();
      }, 500);
    });
  };

  function changeToLocalOrder() {
    setLocalOrder(true)
    setTimeout(() => {
      swal
        .fire({
          html:
            "<h4>Mudar lógica para pedido LOCAL</h4>",
          // icon: "success",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",
          timer: 8000,
        })
    }, 700);

  }

  function changeToDeliveryOrder() {
    setLocalOrder(false)
    setTimeout(() => {
      swal
        .fire({
          html:
            "<h4>Mudar lógica para pedido Delivery</h4>",
          // icon: "success",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",
          timer: 8000,
        })
    }, 700);
  }

  async function getLatLng() {
    const response = await convertAddressToLatLng(clientLogradouro, clientHouseNumber, clientNeighborhood, clientCity, clientCEP)

    console.log('response')
    console.log(response)
    console.log('response.results.geometry.lat')
    console.log(response.results[0].geometry.location.lat)
    setClientLat(response.results[0].geometry.location.lat)

    console.log('response.results.geometry.lng')
    console.log(response.results[0].geometry.location.lng)
    setClientLng(response.results[0].geometry.location.lng)

    getAreas(response.results[0].geometry.location.lat, response.results[0].geometry.location.lng)
  }

  async function getAreas(lat, lng) {
    const areasResponse = await api
      .get(
        "busca-areas-entregas",
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )

    console.log('areasResponse')
    console.log(areasResponse)
    setAreasEntrega(areasResponse.data)

    checkAreas(lat, lng, areasResponse.data)
  }

  async function checkAreas(lat, lng, newAreas) {
    setLoading(true)
    const areas = [];
    console.log('checkAreas')
    console.log('areasEntrega')
    console.log(newAreas)
    console.log('lat')
    console.log(lat)
    console.log('lng')
    console.log(lng)
    const curPosition = new window.google.maps.LatLng(lat, lng);

    newAreas.map(async (area) => {
      console.log('area.id')
      console.log(area.id)
      const areaEntrega = new window.google.maps.Polygon({
        paths: area.coords_area,
      });

      const isWithinPolygon = await window.google.maps.geometry.poly.containsLocation(
        curPosition,
        areaEntrega
      );

      if (isWithinPolygon) {
        areas.push(true);
        console.log(`area true :  ${area.id}`)
        setClientDeliveryValue(area.taxa_entrega)
        // alert(area.id);
        setClientAreaId(area.id);
        setLoading(false)
        setTimeout(() => {
          swal
            .fire({
              html:
                `<h4>Área encontrada, ID: ${area.id}, <br/>Valor: ${area.taxa_entrega}</h4>`,
              icon: "success",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              timer: 5000,
            })
        }, 700);
      } else {
        // console.log(`area false :  ${area.id}`)
        areas.push(false);
      }
    });

  }

  // const callConvertAddressToLatLng = convertAddressToLatLng(clientLogradouro, clientHouseNumber, clientNeighborhood, clientCity, clientCEP)

  const activeButton = {
    width: "15%",
    background: "#202020",
    color: '#fff',
    flex: "1 0 0",
    margin: 10,
    boxShadow: "0 0 4px 0 rgb(255 255 255 / 40%)"
  }

  const inactiveButton = {
    width: "15%",
    background: "#f44336",
    color: '#fff',
    flex: "1 0 0",
    margin: 10
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {/* <Grid xs={12} sm={12} md={12} className={classes.center} style={{ paddingBottom: '5px !important' }}> */}
          <Grid xs={12} sm={12} md={12} style={{ paddingBottom: '5px !important', margin: 'auto', textAlign: 'center' }}>
            <br />
            <Typography variant="h6" className={classes.cardTitle}>
              Criar Novo Pedido
            </Typography>
            {/* <br /> */}
            <Grid xs={12} sm={12} md={12} style={{ paddingTop: 15 }} >
              <Button
                variant="contained"
                size="small"
                style={localOrder ? inactiveButton : activeButton}
                onClick={changeToDeliveryOrder}
              >
                Delivery
              </Button>

              <Button
                variant="contained"
                size="small"
                style={localOrder ? activeButton : inactiveButton}
                onClick={changeToLocalOrder}
              >
                Balcão
              </Button>
            </Grid>
            <Button
              variant="contained"
              size="small"
              style={{ width: "30%", background: "#f44336", color: '#fff', marginTop: 30 }}
              onClick={firstPass}
              hidden={passNumber === 1}
            >
              Voltar
            </Button>
          </Grid>

          {passNumber === 1 ? (
            <>
              <Grid xs={12} sm={12} md={12} className={classes.center}>
                <form autoComplete="off">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>

                      <CustomInput
                        labelText="Nome do Cliente"
                        id="company"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: 'new-password',
                          value: clientName,
                          onChange: (e) =>
                            setClientName(e.target.value),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <InputMask
                        onChange={(e) => setClientPhone(e.target.value)}
                        mask={"(99) 99999-9999"}
                        formatChars={{ 9: "[0-9]" }}
                        value={clientPhone}

                      >
                        {() => (
                          <TextField
                            label="Telefone"
                            type="text"
                            autoComplete="new-password"
                            style={{ marginTop: "28px", width: "100%" }}
                          />
                        )}
                      </InputMask>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>

                      <CustomInput
                        labelText="Email"
                        id="client-email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: 'new-password',
                          value: clientEmail,
                          onChange: (e) =>
                            setClientEmail(e.target.value),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <InputMask
                        onChange={(e) => setClientCEP(e.target.value)}
                        mask="99999-999"
                        // defaultValue={configs.cep}
                        value={clientCEP}
                      >
                        {() => (
                          <TextField
                            label="CEP"
                            type="text"
                            autoComplete="new-password"
                            style={{ marginTop: "27px" }}
                            onKeyPress={event => {
                              if (event.key === 'Enter') {
                                buscaCep()
                              }
                            }}
                          />
                        )}
                      </InputMask>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Logradouro"
                        id="logradouro"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: clientLogradouro,
                          onChange: (e) => setClientLogradouro(e.target.value),
                          onFocus: buscaCep,
                          // defaultValue: configs.logradouro,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Bairro"
                        id="bairro"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: clientNeighborhood,
                          onChange: (e) => setClientNeighborhood(e.target.value),
                          // defaultValue: configs.bairro,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Número"
                        id="numero"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: clientHouseNumber,
                          onChange: (e) => setClientHouseNumber(e.target.value),
                          // defaultValue: configs.numero,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Complemento"
                        id="complemento"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: clientComplement,
                          onChange: (e) => setClientComplement(e.target.value),
                          // defaultValue: configs.cidade,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Cidade"
                        id="cidade"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: clientCity,
                          onChange: (e) => setClientCity(e.target.value),
                          // defaultValue: configs.cidade,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Taxa"
                        id="taxa-entrega"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          // value: 'R$ 99,99',
                          value: clientDeliveryValue,
                          onChange: (e) => setClientDeliveryValue(e.target.value),
                          // defaultValue: configs.cidade,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="Área"
                        id="area-id"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          // value: 'R$ 99,99',
                          value: clientAreaId,
                          // onChange: (e) => setClientDeliveryValue(e.target.value),
                          // defaultValue: configs.cidade,
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                </form>

              </Grid>
              <Grid xs={12} sm={12} md={12} className={classes.center}>
                {/* <br /> */}

                <Button
                  variant="contained"
                  size="small"
                  style={{ width: "40%", background: "#f44336", color: '#fff', flex: "1 0 0", margin: 20 }}
                  onClick={() => getLatLng()}
                >
                  Buscar área
                </Button>

                {/* </Grid>
              <Grid xs={12} sm={12} md={12} className={classes.center}>
                <br /> */}

                <Button
                  variant="contained"
                  size="small"
                  style={{ width: "40%", background: "#f44336", color: '#fff', flex: "1 0 0", margin: 20 }}
                  onClick={secondPass}
                >
                  Continuar
                </Button>

              </Grid>
            </>
          ) : ('')}
          <Grid xs={12} sm={12} md={12} className={classes.center}>
            {/* {categorias.map((categoria) => (
              <>
                <GridContainer>
                  <Grid xs={12} sm={12} md={12} className={classes.center}>
                    <Typography variant="h6" className={classes.cardTitle}>
                      {categoria.categoria}
                    </Typography>
                  </Grid>
                </GridContainer>
              </>
            ))
            } */}
            {passNumber === 2 ? (
              <ProductsList
                categorias={categorias}
                subCategorias={subCategorias}
                produtos={produtos}
              // productsMenu={productsMenu}

              />
            ) : ('')
            }




            {/* {subCategorias.map((subCategoria) => (
                  <>
                    {subCategoria.id_categoria === categoria.id ? (
                      <>
                        <GridContainer>
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.center}
                          >
                            <Typography
                              variant="h6"
                              className={classes.cardTitleSub}
                            >
                              {subCategoria.sub_categoria}
                            </Typography>
                          </Grid>
                        </GridContainer>
                      </>
                    )
                    )}

                    </>
                    )}
)}
)} */}

          </Grid>
        </div>
      )
      }

    </>
  );
}
