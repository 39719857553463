import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "calc(100vw - 310px)",

    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },
  loading: {
    color: "#f44336"
  }
}));

export default function Loading() {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    function tick() {
      // reset when reaching 100%
      setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
    }

    const timer = setInterval(tick, 20);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justify="center"
      style={{ minHeight: "calc(100vh - 240px)" }}
    >
      <div>
        <CircularProgress
          variant="determinate"
          value={progress}
          size={80}
          className={classes.loading}
        />
      </div>
    </Grid>
  );
}
