import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import swal from "sweetalert2";

import api from "../../services/api";

import yoodemoSocket from "services/webSocket";

import moment from "moment";

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import Logout from "@material-ui/icons/ExitToApp";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AdjustIcon from '@material-ui/icons/Adjust';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Badge from "@material-ui/core/Badge";
// core components
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Slide from "@material-ui/core/Slide";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import SwitchButton from "@material-ui/core/Switch";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

import "./styles.css";
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function AdminNavbarLinks() {
  const classes = useStyles();
  const store = useSelector((state) => state);
  const dark = store.isDark.status;

  console.log(store);
  const dispatch = useDispatch();

  const [tempoEstimado, setTempoEstimado] = useState("0:00");
  const [minutosEstimado, setMinutosEstimado] = useState(0);

  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [lojaAberta, setLojaAberta] = useState(store.abreLoja.status);
  const [pedidosNaoAceitos, setPedidosNaoAceitos] = useState(
    store.pedidosNaoAceitos.number
  );

  const [status, setStatus] = useState(false);

  const [online, setOnline] = useState(true);


  const socket =
    yoodemoSocket.getSubscription('japa') ||
    yoodemoSocket.subscribe('japa');

  socket.on("lojaAberta", () => {
    socket.emit("lojaOnlineAberta", { status: store.abreLoja.status });
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    api
      .get(
        "/busca-configs",
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 15000 }
      )
      .then((response) => {
        //console.log(response.data);
        setTempoEstimado(response.data.tempo_estimado);
        if (response.data.aberta) {
          dispatch({ type: "ABRE_LOJA", status: true });
          setLojaAberta(true);
          const data = {
            status: true,
          };
          const socket =
            yoodemoSocket.getSubscription('japa') ||
            yoodemoSocket.subscribe('japa');

          socket.emit("mudaStatusLoja", data);
        } else {
          setTimeout(() => {
            swal.fire({
              html:
                "<h4>O pedido online está fechado</h4><br><h5>Para receber pedidos pelo site, ative o pedido online<h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              timer: 8000,
            });
          }, 3333);
        }
      })
      .catch((error) => {
        swal.fire({
          html: "<h4>Ocorreu algum erro de conexão</h4>",
          icon: "warning",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",
          timer: 2500,
        });
      });
  }, []);

  const handleConnectionChange = (event) => {
    if (event.type == 'offline') {
      // alert("Conexão perdida");
      swal.fire({
        html:
          '<h4>Você perdeu sua conexão com a internet</h4><br />',
        icon: 'warning',
        confirmButtonColor: '#2d3035',
        confirmButtonText: 'Ok',
        timer: 5000,
      });
      setOnline(false);
    }
    if (event.type == 'online') {
      console.log(' Voltou a conexão');
      swal.fire({
        html:
          '<h4>Sua conexão com a internet voltou!</h4><br />',
        icon: 'success',
        confirmButtonColor: '#2d3035',
        confirmButtonText: 'Ok',
        timer: 2500,
      });
      setOnline(true);
    }
  };
  window.addEventListener('online', handleConnectionChange);
  window.addEventListener('offline', handleConnectionChange);

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogOut = () => {
    localStorage.clear();
    swal.fire({
      html: "<h4>LogOut efetuado com sucesso!</h4>",
      icon: "success",
      confirmButtonColor: "#2d3035",
      confirmButtonText: "Ok",
      timer: 5000,
    });
    setTimeout(function () {
      window.location.href = "/login";
    }, 1000);
  };

  async function mudaTempoEstimado({ tempo_estimado, minutos_estimado }) {
    await api
      .post(
        "altera-tempo-estimado",
        { tempo_estimado, minutos_estimado },
        { timeout: 6000 }
      )
      .then(async (response) => {
        setTempoEstimado(tempo_estimado);
        setMinutosEstimado(minutos_estimado);
        setOpenDialog(false);

        swal.fire({
          html: `<h4 className=${classes.swalTitle}>Tempo estimado atualizado com sucesso!</h4>`,
          icon: "success",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",

          customClass: {
            title: "swalTitle",
          },
        });
      });
  }

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChangeDarkMode = (e) => {
    if (e.target.checked) {
      localStorage.setItem("darkMode", "sim");
      dispatch({ type: "IS_DARK", status: true });
    } else {
      dispatch({ type: "IS_DARK", status: false });
      localStorage.setItem("darkMode", "nao");
    }
  };

  const handleChangeAbreLoja = (e) => {
    const socket =
      yoodemoSocket.getSubscription('japa') ||
      yoodemoSocket.subscribe('japa');

    if (e.target.checked) {
      const data = {
        status: true,
      };
      socket.emit("mudaStatusLoja", data);
      dispatch({ type: "ABRE_LOJA", status: true });
      setLojaAberta(true);
    } else {
      const data = {
        status: false,
      };
      socket.emit("mudaStatusLoja", data);
      setLojaAberta(false);

      dispatch({ type: "ABRE_LOJA", status: false });
    }
  };

  return (
    <div>

      <div className={classes.searchWrapper}>
        {online ? (
          <>
            <Button
              style={{ background: 'none', border: 'none', boxShadow: 'none' }}
            >
              <AdjustIcon style={{ color: 'green' }} />
              {' '}
              Online
            </Button>
          </>
        ) : (
          <>
            <Button
              style={{ background: 'none', border: 'none', boxShadow: 'none' }}
            >
              <HighlightOffIcon style={{ color: 'red' }} />
              {' '}
              Offline
            </Button>
          </>
        )}
      </div>

      <div className={classes.searchWrapper}>
        <FormControlLabel
          label={store.isDark.status ? `Light Mode` : `Dark Mode`}
          control={
            <SwitchButton
              checked={dark}
              onChange={handleChangeDarkMode}
              name="checkedA"
              color="secondary"
              size="small"
            />
          }
        />
      </div>

      {/* <div className={classes.searchWrapper}>
        <FormControlLabel
          label={store.abreLoja.status ? `Pedido Aberto` : `Pedido Fechado`}
          control={
            <SwitchButton
              checked={lojaAberta}
              onChange={handleChangeAbreLoja}
              name="checkedB"
              color="secondary"
              size="small"
            />
          }
        />
      </div> */}
      {/* <div className={classes.searchWrapper}>
        <Button
          className={dark ? classes.timeButtonDark : classes.timeButton}
          onClick={handleClickOpen}
          size="small"
        >
          Tempo - {tempoEstimado}
        </Button>
      </div> */}
      {/* <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: classes.margin + " " + classes.search,
          }}
          inputProps={{
            placeholder: "Pesquisar",
            inputProps: {
              "aria-label": "Pesquisar",
            },
          }}
        />
        <Button color="white" aria-label="edit" justIcon round>
          <Search />
        </Button>
      </div> */}
      {/* <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        <Dashboard className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Home</p>
        </Hidden>
      </Button> */}
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={dark ? classes.buttonLinkDark : classes.buttonLink}
        >
          <Badge
            color="error"
            badgeContent={store.pedidosNaoAceitos.number}
            className={store.pedidosNaoAceitos.number ? "pulse" : ""}
          >
            <Notifications />
          </Badge>

          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      <SentimentVeryDissatisfiedIcon /> &nbsp; Pedidos não
                      aceitos {store.pedidosNaoAceitos.number}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        {/* <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Perfil
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Configurações
                    </MenuItem>
                    <Divider light />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers> */}
        {/* <Link to="/login" className={classes.icons}> */}
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Dashboard"
          className={dark ? classes.buttonLinkDark : classes.buttonLink}
          onClick={handleLogOut}
        >
          <Logout className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Sair</p>
          </Hidden>
        </Button>
        {/* </Link> */}

        <Dialog
          // onClose={() => { }}
          aria-labelledby="simple-dialog-title"
          open={openDialog}
          onClose={handleClose}
          TransitionComponent={Transition}
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogTitle id="simple-dialog-title">Tempo estimado</DialogTitle>
          <List>
            <ListItem
              button
              onClick={() =>
                mudaTempoEstimado({
                  tempo_estimado: "0:30",
                  minutos_estimado: 30,
                })
              }
            >
              <ListItemAvatar>
                <AccessTimeIcon />
              </ListItemAvatar>
              <ListItemText primary="0:30" />
            </ListItem>

            <ListItem
              button
              onClick={() =>
                mudaTempoEstimado({
                  tempo_estimado: "0:45",
                  minutos_estimado: 45,
                })
              }
            >
              <ListItemAvatar>
                <AccessTimeIcon />
              </ListItemAvatar>
              <ListItemText primary="0:45" />
            </ListItem>

            <ListItem
              button
              onClick={() =>
                mudaTempoEstimado({
                  tempo_estimado: "1:00",
                  minutos_estimado: 60,
                })
              }
            >
              <ListItemAvatar>
                <AccessTimeIcon />
              </ListItemAvatar>
              <ListItemText primary="1:00" />
            </ListItem>

            <ListItem
              button
              onClick={() =>
                mudaTempoEstimado({
                  tempo_estimado: "1:15",
                  minutos_estimado: 75,
                })
              }
            >
              <ListItemAvatar>
                <AccessTimeIcon />
              </ListItemAvatar>
              <ListItemText primary="1:15" />
            </ListItem>

            <ListItem
              button
              onClick={() =>
                mudaTempoEstimado({
                  tempo_estimado: "1:30",
                  minutos_estimado: 90,
                })
              }
            >
              <ListItemAvatar>
                <AccessTimeIcon />
              </ListItemAvatar>
              <ListItemText primary="1:30" />
            </ListItem>

            <ListItem
              button
              onClick={() =>
                mudaTempoEstimado({
                  tempo_estimado: "2:00",
                  minutos_estimado: 120,
                })
              }
            >
              <ListItemAvatar>
                <AccessTimeIcon />
              </ListItemAvatar>
              <ListItemText primary="2:00" />
            </ListItem>
          </List>
        </Dialog>
      </div>
    </div>
  );
}
