import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import api from "../../services/api";

import Loading from "../Loading";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert2";
import CurrencyInput from "react-currency-input";

import GridItem from "components/Grid/GridItem.js";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import LazyLoad from "react-lazyload";
import Fade from "@material-ui/core/Fade";

import Typography from "@material-ui/core/Typography";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

import Button from "@material-ui/core/Button";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";

import TextField from "@material-ui/core/TextField";

import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SwitchButton from "@material-ui/core/Switch";
import noImage from "assets/img/no-image.png";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/views/darkDashboardStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Cardapio() {
  const dark = false;
  const store = useSelector((state) => state);
  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);
  const classes = useStyles();

  const [produtos, setProdutos] = useState([]);

  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);

  const [detalhesProduto, setDetalhesProduto] = useState([]);
  const [nomeProduto, setNomeProduto] = useState("");
  const [descricaoProduto, setDescricaoProduto] = useState("");
  const [valorProduto, setValorProduto] = useState();
  const [valorBrotinhoProduto, setValorBrotinhoProduto] = useState();
  const [valorMediaProduto, setValorMediaProduto] = useState();
  const [valorGrandeProduto, setValorGrandeProduto] = useState();
  const [valorGiganteProduto, setValorGiganteProduto] = useState();
  const [imagemProduto, setImagemProduto] = useState("");

  const [imagemProdutoEdit, setImagemProdutoEdit] = useState();
  const [
    imagemProdutoEditPreviewUrl,
    setImagemProdutoEditPreviewUrl,
  ] = useState();

  const [filtraProdutos, setFiltraProdutos] = useState([]);

  const [openDialogProduto, setOpenDialogProduto] = useState(false);
  const [loading, setLoading] = useState(true);

  const [refreshData, setRefreshData] = useState(0);

  const formato = {
    minimumFractionDigits: 2,
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    async function loadProdutos() {
      await api
        .get(
          "cardapio",
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          console.log(response.data);
          setLoading(false);
          setCategorias(response.data.categorias);
          setSubCategorias(response.data.sub_categorias);
          setProdutos(response.data.produtos);
        })
        .catch(() => {
          setLoading(false);

          swal
            .fire({
              html:
                "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              //  timer: 5000
            })
            .then(() => {
              // window.location.reload();
            });
          // setTimeout(() => window.location.reload(), 5000);
        });
    }

    loadProdutos();
  }, [refreshData]);

  async function loadProdutosUpdate() {
    await api
      .get(
        "cardapio",
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        console.log(response.data);
        setLoading(false);
        setCategorias(response.data.categorias);
        setSubCategorias(response.data.sub_categorias);
        setProdutos(response.data.produtos);
      })
      .catch(() => {
        setLoading(false);

        swal
          .fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            //  timer: 5000
          })
          .then(() => {
            // window.location.reload();
          });
        // setTimeout(() => window.location.reload(), 5000);
      });
  }

  async function handleIndisponivel(id_produto) {
    // alert(`Produto ${id_produto} Indisponivel`);
    setLoading(true);
    api
      .put(
        `altera-status-produto/${id_produto}`,
        { status: "indisponivel" },
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        // console.log(response.data);

        swal
          .fire({
            html:
              "<h4>O produto foi atualizado</h4><br /><h5>Produto indisponível</h5>",
            icon: "success",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 3500,
          })
          .then(() => {
            // window.location.reload();
          });

        const newStatus = produtos.map((produto) => {
          return produto.id === id_produto
            ? { ...produto, status: "indisponivel" }
            : produto;
        });
        setProdutos(newStatus);

        const newStatus1 = filtraProdutos.map((produto) => {
          return produto.id === id_produto
            ? { ...produto, status: null }
            : produto;
        });
        setFiltraProdutos(newStatus1);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

        swal
          .fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            //  timer: 5000
          })
          .then(() => {
            // window.location.reload();
          });
        // setTimeout(() => window.location.reload(), 5000);
      });
  }

  async function handleDisponivel(id_produto) {
    // alert(`Produto ${id_produto} Indisponivel`);
    setLoading(true);
    api
      .put(
        `altera-status-produto/${id_produto}`,
        { status: null },
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        // console.log(response.data);

        swal
          .fire({
            html:
              "<h4>O produto foi atualizado</h4><br /><h5>Produto disponível</h5>",
            icon: "success",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 3500,
          })
          .then(() => {
            // window.location.reload();
          });

        const newStatus = produtos.map((produto) => {
          return produto.id === id_produto
            ? { ...produto, status: null }
            : produto;
        });
        setProdutos(newStatus);

        const newStatus1 = filtraProdutos.map((produto) => {
          return produto.id === id_produto
            ? { ...produto, status: null }
            : produto;
        });
        setFiltraProdutos(newStatus1);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

        swal
          .fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            //  timer: 5000
          })
          .then(() => {
            // window.location.reload();
          });
        // setTimeout(() => window.location.reload(), 5000);
      });
  }

  async function handleSelectProduto(id_produto) {
    setLoading(true);
    api
      .get(
        `busca-produto/${id_produto}`,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        setLoading(false);
        setDetalhesProduto(response.data);

        setNomeProduto(response.data.produto);
        setDescricaoProduto(response.data.descricao);
        setValorProduto(response.data.valor);
        setValorBrotinhoProduto(response.data.valor_brotinho);
        setValorMediaProduto(response.data.valor_media);
        setValorGrandeProduto(response.data.valor_grande);
        setValorGiganteProduto(response.data.valor_gigante);
        setImagemProduto(response.data.imagem);
        setOpenDialogProduto(true);
      })
      .catch(() => {
        setLoading(false);

        swal
          .fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            //  timer: 5000
          })
          .then(() => {
            // window.location.reload();
          });
        // setTimeout(() => window.location.reload(), 5000);
      });
  }

  async function handleFiltraProdutos(event) {
    setLoading(true);
    if (event.length > 3) {
      const data = produtos.filter(
        (item) =>
          item.descricao
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .match(
              new RegExp(
                event.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                "i"
              )
            ) ||
          item.produto
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .match(
              new RegExp(
                event.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                "i"
              )
            ) ||
          item.sub_categoria
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .match(
              new RegExp(
                event.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                "i"
              )
            )
      );
      // .map(({ produto }) => ({ produto }));

      setFiltraProdutos(data);
      setLoading(false);
    }

    if (event.length == 0) {
      setFiltraProdutos([]);
      setLoading(false);
    }
  }

  const handleUpdateProduto = async (idProduto) => {
    setLoading(true);
    setOpenDialogProduto(false);

    let valor_gigante = null;
    let valor_grande = null;
    let valor_media = null;
    let valor_brotinho = null;
    let valor = null;

    if (valorGiganteProduto) {
      valor_gigante = parseFloat(
        valorGiganteProduto.replace("R$ ", "").replace(",", ".")
      ).toFixed(2);
    }
    if (valorGrandeProduto) {
      valor_grande = parseFloat(
        valorGrandeProduto.replace("R$ ", "").replace(",", ".")
      ).toFixed(2);
    }
    if (valorMediaProduto) {
      valor_media = parseFloat(
        valorMediaProduto.replace("R$ ", "").replace(",", ".")
      ).toFixed(2);
    }
    if (valorBrotinhoProduto) {
      valor_brotinho = parseFloat(
        valorBrotinhoProduto.replace("R$ ", "").replace(",", ".")
      ).toFixed(2);
    }
    if (valorProduto) {
      valor = parseFloat(
        valorProduto.replace("R$ ", "").replace(",", ".")
      ).toFixed(2);
    }

    await api
      .post(
        `atualiza-produto/${idProduto}`,
        {
          id: idProduto,
          produto: nomeProduto,
          descricao: descricaoProduto,
          valor,
          valor_brotinho,
          valor_media,
          valor_grande,
          valor_gigante,
        },
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        const newStatus = await produtos.map((produto) => {
          return produto.id === idProduto ? response.data.produto : produto;
        });
        setProdutos(newStatus);

        const newStatus1 = await filtraProdutos.map((produto) => {
          return produto.id === idProduto ? response.data.produto : produto;
        });
        setFiltraProdutos(newStatus1);

        if (imagemProdutoEdit) {
          api
            .delete(
              `delete-imagem-produto/${idProduto}`,
              { headers: { Authorization: `Bearer ${token}` } },
              { timeout: 10000 }
            ).then(async (response) => {
              const formData = new FormData();
              formData.append("image", imagemProdutoEdit);
              const res = await api
                .post(`imagem-produto/${idProduto}`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then(async (response) => {
                  const newStatus = await produtos.map((produto) => {
                    return produto.id === idProduto ? response.data : produto;
                  });
                  setProdutos(newStatus);

                  const newStatus1 = await filtraProdutos.map((produto) => {
                    return produto.id === idProduto ? response.data : produto;
                  });
                  setFiltraProdutos(newStatus1);
                  setLoading(false);
                });
            })

        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

        swal.fire({
          html:
            "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
          icon: "warning",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",
          //  timer: 5000
        });
      });
  };

  const changeFileProdutoEdit = (e) => {
    setImagemProdutoEdit(e.target.files[0]);

    setImagemProdutoEditPreviewUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleDeleteProduto = async (idProduto) => {
    swal
      .fire({
        html: "<h4>Deseja realmente excluir o produto ?</h4><br />",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#2d3035",
        confirmButtonColor: "#2d3035",
        confirmButtonText: "Excluir",
        //  timer: 5000
      })
      .then(async (result) => {
        setLoading(true);
        if (result.value) {
          api
            .delete(
              `exclui-produto/${idProduto}`,
              { headers: { Authorization: `Bearer ${token}` } },
              { timeout: 10000 }
            )
            .then(async (response) => {
              api
                .delete(
                  `delete-imagem-produto/${idProduto}`,
                  { headers: { Authorization: `Bearer ${token}` } },
                  { timeout: 10000 }
                ).then(async (response) => {
                  setLoading(false);
                  swal.fire({
                    html: "<h4>Produto excluído</h4>",
                    icon: "error",
                    confirmButtonColor: "#2d3035",
                    confirmButtonText: "Ok",
                    timer: 6000,
                  });
                  setRefreshData(refreshData + 1);
                })
            });
        }
      });
  };

  return (
    <>
      <TextField
        name="pesquisar"
        label="Pesquisar por Produto ou Descrição"
        onChange={(event) => handleFiltraProdutos(event.target.value)}
        fullWidth
      />
      <br />
      <br />
      {filtraProdutos.length > 0 ? (
        <>
          <GridContainer>
            <Grid xs={12} sm={12} md={12} className={classes.center}>
              <Typography variant="h6" className={classes.cardTitle}>
                Resultado da Pesquisa
              </Typography>
            </Grid>
          </GridContainer>
          <GridContainer>
            {filtraProdutos.map((produto) => (
              <>
                <GridItem xs={12} sm={6} md={4} xl={3} key={produto.id}>
                  <Card className={classes.cardBody}>
                    <CardHeader stats icon>
                      {produto.status === "indisponivel" ? (
                        <CardIcon color="danger">
                          <ErrorOutlineIcon />
                        </CardIcon>
                      ) : (
                        <CardIcon
                          className={classes.transitoTilte}
                          color="success"
                        >
                          <CheckCircleOutlineIcon />
                        </CardIcon>
                      )}
                    </CardHeader>
                    <CardFooter stat className={classes.cardFooterCardapio}>
                      <h3
                        className={
                          dark ? classes.cardTitleDark : classes.cardTitle
                        }
                      >
                        <small>{produto.produto}</small>
                      </h3>

                      <br />
                      <p
                        className={
                          dark ? classes.cardTitleDark : classes.cardTitle
                        }
                      >
                        {produto.descricao}
                      </p>
                      <br />
                      {produto.imagem ? (
                        <>
                          <LazyLoad
                            width={100}
                            height={100}
                            debounce={false}
                            offset={100}
                            placeholder={
                              <img
                                className={classes.mediaCardapio}
                                src="https://s3.amazonaws.com/store.p2food.ml/images/blur-min.png"
                                alt={produto.produto}
                              />
                            }
                          >
                            <Fade in={true} timeout={777}>
                              <img
                                className={classes.mediaCardapio}
                                src={produto.imagem}
                                alt={produto.produto}
                              />
                            </Fade>
                          </LazyLoad>
                        </>
                      ) : (
                        <img
                          src="https://s3.amazonaws.com/storage.n2delivery.com.br/no-image.jpg"
                          alt={produto.produto}
                          className={classes.mediaCardapio}
                        />
                      )}
                      <br />
                    </CardFooter>

                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="stretch"
                      className={classes.cardButtons}
                    >
                      <p>
                        <small>
                          {produto.id_categoria == 1 ||
                            produto.id_categoria == 2 ? (
                            <>
                              {produto.valor_gigante
                                ? `GG R$ ${parseFloat(
                                  produto.valor_gigante
                                ).toLocaleString("pt-BR", formato)} \u00A0 `
                                : ""}

                              {produto.valor_grande
                                ? `G R$ ${parseFloat(
                                  produto.valor_grande
                                ).toLocaleString("pt-BR", formato)} \u00A0 `
                                : ""}

                              {produto.valor_media
                                ? `M R$ ${parseFloat(
                                  produto.valor_media
                                ).toLocaleString("pt-BR", formato)} \u00A0 `
                                : ""}

                              {produto.valor_brotinho
                                ? `P R$ ${parseFloat(
                                  produto.valor_brotinho
                                ).toLocaleString("pt-BR", formato)} \u00A0 `
                                : ""}
                            </>
                          ) : (
                            <>
                              {produto.valor
                                ? `R$ ${parseFloat(
                                  produto.valor
                                ).toLocaleString("pt-BR", formato)} \u00A0 `
                                : ""}
                            </>
                          )}
                        </small>
                      </p>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="stretch"
                      className={classes.cardButtons}
                    >
                      {!produto.status ? (
                        <FormControlLabel
                          label="Disponível"
                          control={
                            <SwitchButton
                              checked={true}
                              onChange={() => handleIndisponivel(produto.id)}
                              name="checkedB"
                              color="secondary"
                              size="small"
                            />
                          }
                        />
                      ) : (
                        <FormControlLabel
                          label="Disponível"
                          control={
                            <SwitchButton
                              checked={false}
                              onChange={() => handleDisponivel(produto.id)}
                              name="checkedB"
                              color="secondary"
                              size="small"
                            />
                          }
                        />
                      )}
                      <Button
                        variant="contained"
                        className={classes.statusButton}
                        onClick={() => handleDeleteProduto(produto.id)}
                      >
                        <DeleteIcon />
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.statusButton}
                        onClick={() => handleSelectProduto(produto.id)}
                      >
                        <EditIcon />
                      </Button>
                    </Grid>
                  </Card>
                </GridItem>
              </>
            ))}
          </GridContainer>
        </>
      ) : (
        ""
      )}
      <br />
      {loading ? (
        <Loading />
      ) : (
        <>
          {filtraProdutos.length > 0 ? (
            ""
          ) : (
            <>
              {categorias.map((categoria) => (
                <>
                  <GridContainer>
                    <Grid xs={12} sm={12} md={12} className={classes.center}>
                      <Typography variant="h6" className={classes.cardTitle}>
                        {categoria.categoria}
                      </Typography>
                    </Grid>
                  </GridContainer>

                  {subCategorias.map((subCategoria) => (
                    <>
                      {subCategoria.id_categoria === categoria.id ? (
                        <>
                          <GridContainer>
                            <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              className={classes.center}
                            >
                              <Typography
                                variant="h6"
                                className={classes.cardTitleSub}
                              >
                                {subCategoria.sub_categoria}
                              </Typography>
                            </Grid>
                          </GridContainer>

                          <GridContainer>
                            {produtos.map((produto) => (
                              <>
                                {produto.id_sub_categoria
                                  == subCategoria.id ? (
                                  <GridItem
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    xl={3}
                                    key={produto.id}
                                  >
                                    <Card className={classes.cardBody}>
                                      <CardHeader stats icon>
                                        {produto.status === "indisponivel" ? (
                                          <CardIcon color="danger">
                                            <ErrorOutlineIcon />
                                          </CardIcon>
                                        ) : (
                                          <CardIcon
                                            className={classes.transitoTilte}
                                            color="success"
                                          >
                                            <CheckCircleOutlineIcon />
                                          </CardIcon>
                                        )}
                                      </CardHeader>
                                      <CardFooter
                                        stat
                                        className={classes.cardFooterCardapio}
                                      >
                                        <h3
                                          className={
                                            dark
                                              ? classes.cardTitleDark
                                              : classes.cardTitle
                                          }
                                        >
                                          <small>{produto.produto}</small>
                                        </h3>

                                        <br />
                                        <p
                                          className={
                                            dark
                                              ? classes.cardTitleDark
                                              : classes.cardTitle
                                          }
                                        >
                                          {produto.descricao}
                                        </p>
                                        <br />
                                        {produto.imagem ? (
                                          <>
                                            <img
                                              className={classes.mediaCardapio}
                                              src={produto.imagem}
                                              alt={produto.produto}
                                            />
                                          </>
                                        ) : (
                                          <img
                                            src="https://s3.amazonaws.com/storage.n2delivery.com.br/no-image.jpg"
                                            alt={produto.produto}
                                            className={classes.mediaCardapio}
                                          />
                                        )}
                                        <br />
                                      </CardFooter>
                                      <Grid
                                        container
                                        direction="row"
                                        justify="space-around"
                                        alignItems="stretch"
                                        className={classes.cardButtons}
                                      >
                                        <p>
                                          <small>
                                            <>
                                              {produto.valor_gigante
                                                ? `GG R$ ${parseFloat(
                                                  produto.valor_gigante
                                                ).toLocaleString(
                                                  "pt-BR",
                                                  formato
                                                )} \u00A0 `
                                                : ""}

                                              {produto.valor_grande
                                                ? `G R$ ${parseFloat(
                                                  produto.valor_grande
                                                ).toLocaleString(
                                                  "pt-BR",
                                                  formato
                                                )} \u00A0 `
                                                : ""}

                                              {produto.valor_media
                                                ? `M R$ ${parseFloat(
                                                  produto.valor_media
                                                ).toLocaleString(
                                                  "pt-BR",
                                                  formato
                                                )} \u00A0 `
                                                : ""}

                                              {produto.valor_brotinho
                                                ? `P R$ ${parseFloat(
                                                  produto.valor_brotinho
                                                ).toLocaleString(
                                                  "pt-BR",
                                                  formato
                                                )} \u00A0 `
                                                : ""}
                                            </>
                                            <>
                                              {produto.valor
                                                ? `R$ ${parseFloat(
                                                  produto.valor
                                                ).toLocaleString(
                                                  "pt-BR",
                                                  formato
                                                )} \u00A0 `
                                                : ""}
                                            </>
                                          </small>
                                        </p>
                                      </Grid>

                                      <Grid
                                        container
                                        direction="row"
                                        justify="space-around"
                                        alignItems="stretch"
                                        className={classes.cardButtons}
                                      >
                                        {!produto.status ? (
                                          <FormControlLabel
                                            label="Disponível"
                                            control={
                                              <SwitchButton
                                                checked={true}
                                                onChange={() =>
                                                  handleIndisponivel(produto.id)
                                                }
                                                name="checkedB"
                                                color="secondary"
                                                size="small"
                                              />
                                            }
                                          />
                                        ) : (
                                          <FormControlLabel
                                            label="Indisponível"
                                            control={
                                              <SwitchButton
                                                checked={false}
                                                onChange={() =>
                                                  handleDisponivel(produto.id)
                                                }
                                                name="checkedB"
                                                color="secondary"
                                                size="small"
                                              />
                                            }
                                          />
                                        )}
                                        <Button
                                          variant="contained"
                                          className={classes.statusButton}
                                          onClick={() =>
                                            handleDeleteProduto(produto.id)
                                          }
                                        >
                                          <DeleteIcon />
                                        </Button>
                                        <Button
                                          variant="contained"
                                          className={classes.statusButton}
                                          onClick={() =>
                                            handleSelectProduto(produto.id)
                                          }
                                        >
                                          <EditIcon />
                                        </Button>
                                      </Grid>
                                    </Card>
                                  </GridItem>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                          </GridContainer>
                          <div className={classes.hr} />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </>
              ))}
            </>
          )}
        </>
      )}

      <Dialog
        onClose={() => { }}
        open={openDialogProduto}
        onClose={() => setOpenDialogProduto(false)}
        TransitionComponent={Transition}
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-title">
          Editar Produto: {detalhesProduto.produto}
        </DialogTitle>
        <br />
        <DialogContent>
          <GridContainer>
            <Grid item md={12} className={classes.fieldDialogFinaliza}>
              <TextField
                name="produto"
                label="Produto"
                value={nomeProduto}
                onChange={(event) => setNomeProduto(event.target.value)}
                fullWidth
              />
            </Grid>
          </GridContainer>
          <br />
          <GridContainer>
            <Grid item md={12} className={classes.fieldDialogFinaliza}>
              <TextField
                name="descricao"
                label="Descrição"
                value={descricaoProduto}
                onChange={(event) => setDescricaoProduto(event.target.value)}
                fullWidth
              />
            </Grid>
          </GridContainer>

          {detalhesProduto.valor_gigante ? (
            <>
              <br />
              <GridContainer>
                <Grid item md={12} className={classes.fieldDialogFinaliza}>
                  <InputLabel className={classes.labelMoney}>
                    Valor Gigante
                  </InputLabel>

                  <CurrencyInput
                    className={classes.inputMoney}
                    decimalSeparator=","
                    thousandSeparator=""
                    prefix="R$ "
                    value={valorGiganteProduto}
                    onChangeEvent={(event) =>
                      setValorGiganteProduto(event.target.value)
                    }
                  />
                </Grid>
              </GridContainer>
            </>
          ) : (
            ""
          )}

          {detalhesProduto.valor_grande ? (
            <>
              <br />
              <GridContainer>
                <Grid item md={12} className={classes.fieldDialogFinaliza}>
                  <InputLabel className={classes.labelMoney}>
                    Valor Grande
                  </InputLabel>

                  <CurrencyInput
                    className={classes.inputMoney}
                    decimalSeparator=","
                    thousandSeparator=""
                    prefix="R$ "
                    value={valorGrandeProduto}
                    onChangeEvent={(event) =>
                      setValorGrandeProduto(event.target.value)
                    }
                  />
                </Grid>
              </GridContainer>
            </>
          ) : (
            ""
          )}

          {detalhesProduto.valor_media ? (
            <>
              <br />
              <GridContainer>
                <Grid item md={12} className={classes.fieldDialogFinaliza}>
                  <InputLabel className={classes.labelMoney}>
                    Valor Média
                  </InputLabel>

                  <CurrencyInput
                    className={classes.inputMoney}
                    decimalSeparator=","
                    thousandSeparator=""
                    prefix="R$ "
                    value={valorMediaProduto}
                    onChangeEvent={(event) =>
                      setValorMediaProduto(event.target.value)
                    }
                  />
                </Grid>
              </GridContainer>
            </>
          ) : (
            ""
          )}

          {detalhesProduto.valor_brotinho ? (
            <>
              <br />
              <GridContainer>
                <Grid item md={12} className={classes.fieldDialogFinaliza}>
                  <InputLabel className={classes.labelMoney}>
                    Valor Brotinho
                  </InputLabel>

                  <CurrencyInput
                    className={classes.inputMoney}
                    decimalSeparator=","
                    thousandSeparator=""
                    prefix="R$ "
                    value={valorBrotinhoProduto}
                    onChangeEvent={(event) =>
                      setValorBrotinhoProduto(event.target.value)
                    }
                  />
                </Grid>
              </GridContainer>
            </>
          ) : (
            ""
          )}

          {detalhesProduto.valor ? (
            <>
              <br />
              <GridContainer>
                <Grid item md={12} className={classes.fieldDialogFinaliza}>
                  <InputLabel className={classes.labelMoney}>Valor</InputLabel>

                  <CurrencyInput
                    className={classes.inputMoney}
                    decimalSeparator=","
                    thousandSeparator=""
                    prefix="R$ "
                    value={valorProduto}
                    onChangeEvent={(event) =>
                      setValorProduto(event.target.value)
                    }
                  />
                </Grid>
              </GridContainer>
            </>
          ) : (
            ""
          )}
          <>
            <br />
            <br />
            <input
              accept="image/*"
              className={classes.input}
              style={{ display: "none" }}
              id="raised-button-file5"
              onChange={changeFileProdutoEdit}
              type="file"
            />
            <label htmlFor="raised-button-file5">
              {imagemProdutoEditPreviewUrl ? (
                <img
                  src={imagemProdutoEditPreviewUrl}
                  className={classes.media}
                />
              ) : (
                <img
                  className={classes.mediaCardapio}
                  src={
                    detalhesProduto.imagem ? detalhesProduto.imagem : noImage
                  }
                  alt={detalhesProduto.produto}
                />
              )}
            </label>
          </>
        </DialogContent>
        <br />
        <DialogActions>
          <Button
            onClick={() => setOpenDialogProduto(false)}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Cancelar
          </Button>

          <Button
            onClick={() => handleUpdateProduto(detalhesProduto.id)}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
