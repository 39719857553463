import React, { useState, useEffect } from "react";
import Loading from "../Loading";

import api from "../../services/api";

import CurrencyInput from "react-currency-input";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert2";

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function CriarProdutos() {
  const classes = useStyles();

  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);

  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
  const [subCategoriaSelecionada, setSubCategoriaSelecionada] = useState(null);

  const [nomeProduto, setNomeProduto] = useState("");
  const [descricaoProduto, setDescricaoProduto] = useState(" ");

  const [valorGigante, setValorGigante] = useState("R$ 0,00");
  const [valorGrande, setValorGrande] = useState("R$ 0,00");
  const [valorMedia, setValorMedia] = useState("R$ 0,00");
  const [valorBrotinho, setValorBrotinho] = useState("R$ 0,00");
  const [valorProduto, setValorProduto] = useState("R$ 0,00");

  const [imagemProduto, setImagemProduto] = useState();
  const [imagemNome, setImagemNome] = useState("Escolha uma imagem");
  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  const [categoriaPizzas, setCategoriaPizzas] = useState(false);

  const [nomeSubCategoria, setNomeSubCategoria] = useState("");

  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    setLoading(true);
    async function loadCategorias() {
      await api
        .get(
          "cardapio",
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          setLoading(false);
          setCategorias(response.data.categorias);
          setSubCategorias(response.data.sub_categorias);
        })
        .catch(() => {
          setLoading(false);

          swal
            .fire({
              html:
                "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              //  timer: 5000
            })
            .then(() => {
              // window.location.reload();
            });
          // setTimeout(() => window.location.reload(), 5000);
        });
    }

    loadCategorias();
  }, []);

  const handleChangeCategoria = async (event) => {
    setCategoriaSelecionada(event.target.value);
    const pizza = "pizza";
    const categoria1 = await categorias.filter((categoria) => {
      return categoria.id === event.target.value;
    });
    console.log(categoria1);
    if (
      categoria1[0].categoria
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .match(
          new RegExp(
            pizza.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            "i"
          )
        )
    ) {
      setCategoriaPizzas(true);
    } else {
      setCategoriaPizzas(false);
    }
    setSubCategoriaSelecionada();
  };

  const handleChangeSubCategoria = (event) => {
    setSubCategoriaSelecionada(event.target.value);
    const nomeSubCategoria = subCategorias.filter(
      (x) => x.id === event.target.value
    );

    setNomeSubCategoria(nomeSubCategoria[0].sub_categoria);
  };

  async function verificaValores() {
    let data = new FormData();
    let valor_gigante = null;
    let valor_grande = null;
    let valor_media = null;
    let valor_brotinho = null;
    let valor = null;

    if (valorGigante != "R$ 0,00") {
      valor_gigante = parseFloat(
        valorGigante.replace("R$ ", "").replace(",", ".")
      ).toFixed(2);
    }

    if (valorGrande != "R$ 0,00") {
      valor_grande = parseFloat(
        valorGrande.replace("R$ ", "").replace(",", ".")
      ).toFixed(2);
    }
    if (valorMedia != "R$ 0,00") {
      valor_media = parseFloat(
        valorMedia.replace("R$ ", "").replace(",", ".")
      ).toFixed(2);
    }
    if (valorBrotinho != "R$ 0,00") {
      valor_brotinho = parseFloat(
        valorBrotinho.replace("R$ ", "").replace(",", ".")
      ).toFixed(2);
    }
    if (valorProduto != "R$ 0,00") {
      valor = parseFloat(
        valorProduto.replace("R$ ", "").replace(",", ".")
      ).toFixed(2);
    }
    return (data = {
      id_categoria: categoriaSelecionada,
      id_sub_categoria: subCategoriaSelecionada,
      sub_categoria: nomeSubCategoria,
      produto: nomeProduto,
      descricao: descricaoProduto,
      valor_gigante,
      valor_grande,
      valor_media,
      valor_brotinho,
      valor,
    });
  }

  async function handleCadastraProduto() {
    setLoading(true);
    if (nomeProduto == "") {
      swal
        .fire({
          html:
            "<h4>O nome do produto é obrigatório</h4><br /><h5>Por favor tente novamente</h5>",
          icon: "warning",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",
          timer: 10000,
        })
        .then(() => {});
      setLoading(false);
    }

    verificaValores().then((data) => {
      api
        .post(
          "cadastra-produto",
          data,
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          if (response.data) {
            if (imagemProduto) {
              const id_produto = response.data.id;
              const formData = new FormData();
              formData.append("image", imagemProduto);
              const res = await api.post(
                `imagem-produto/${id_produto}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
            }

            swal
              .fire({
                html: "<h4>Produto cadastrado com sucesso!</h4>",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#2d3035",
                cancelButtonText: "Continuar",
                cancelButtonColor: "#2d3035",
                confirmButtonText: "Limpar Formulário",
                timer: 10000,
              })
              .then((result) => {
                if (result.value) {
                  setCategoriaSelecionada();
                  setSubCategoriaSelecionada();
                  setNomeProduto("");
                  setDescricaoProduto("");
                  setValorGigante("R$ 0,00");
                  setValorGrande("R$ 0,00");
                  setValorMedia("R$ 0,00");
                  setValorBrotinho("R$ 0,00");
                  setValorProduto("R$ 0,00");
                }
              });
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status == 400) {
            swal.fire({
              html:
                "<h4>Tipo de arquivo inválido</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "error",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              //  timer: 5000
            });
          } else {
            swal.fire({
              html:
                "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              //  timer: 5000
            });
          }
        });
    });
  }

  const changeFile = async (e) => {
    let reader = new FileReader();
    setImagemProduto(e.target.files[0]);
    setImagemNome(e.target.files[0].name);

    setImagePreviewUrl(URL.createObjectURL(e.target.files[0]));

    // const formData = new FormData();
    // formData.append("image", e.target.files[0]);
    // const res = await api.put("/teste-imagem", formData, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // });
    // console.log(res);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Grid
            container
            spacing={5}
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{ minHeight: "calc(100vh - 300px)" }}
          >
            <Grid item md={6}>
              <FormControl className={classes.formControl}>
                <InputLabel>Categoria</InputLabel>
                <Select
                  value={categoriaSelecionada}
                  onChange={handleChangeCategoria}
                >
                  {categorias.map((categoria) => (
                    <MenuItem value={categoria.id}>
                      {categoria.categoria}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <br />

            {categoriaSelecionada ? (
              <Grid item md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Sub Categoria</InputLabel>
                  <Select
                    value={subCategoriaSelecionada}
                    onChange={handleChangeSubCategoria}
                    name="ops"
                  >
                    {subCategorias
                      .filter(
                        (subCategoria) =>
                          subCategoria.id_categoria == categoriaSelecionada
                      )
                      .map((subCategoria) => (
                        <MenuItem
                          value={subCategoria.id}
                          yoo={subCategoria.sub_categoria}
                        >
                          {subCategoria.sub_categoria}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
            <br />
            {categoriaSelecionada ? (
              <>
                <Grid item md={6}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label="Nome do Produto"
                      value={nomeProduto}
                      onChange={(event) => setNomeProduto(event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <br />
                <Grid item md={6}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      multiline
                      label="Descrição do Produto"
                      value={descricaoProduto}
                      onChange={(event) =>
                        setDescricaoProduto(event.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <br />
              </>
            ) : (
              ""
            )}

            {categoriaPizzas ? (
              <>
                <br />
                <Grid item md={6}>
                  <InputLabel className={classes.labelMoney}>
                    Valor Gigante
                  </InputLabel>

                  <CurrencyInput
                    className={classes.inputMoney}
                    decimalSeparator=","
                    thousandSeparator=""
                    prefix="R$ "
                    value={valorGigante}
                    onChangeEvent={(event) =>
                      setValorGigante(event.target.value)
                    }
                  />
                </Grid>
                <br />
                <Grid item md={6}>
                  <InputLabel className={classes.labelMoney}>
                    Valor Grande
                  </InputLabel>

                  <CurrencyInput
                    className={classes.inputMoney}
                    decimalSeparator=","
                    thousandSeparator=""
                    prefix="R$ "
                    value={valorGrande}
                    onChangeEvent={(event) =>
                      setValorGrande(event.target.value)
                    }
                  />
                </Grid>
                <br />

                <Grid item md={6}>
                  <InputLabel className={classes.labelMoney}>
                    Valor Média
                  </InputLabel>

                  <CurrencyInput
                    className={classes.inputMoney}
                    decimalSeparator=","
                    thousandSeparator=""
                    prefix="R$ "
                    value={valorMedia}
                    onChangeEvent={(event) => setValorMedia(event.target.value)}
                  />
                </Grid>
                <br />

                <Grid item md={6}>
                  <InputLabel className={classes.labelMoney}>
                    Valor Brotinho
                  </InputLabel>

                  <CurrencyInput
                    className={classes.inputMoney}
                    decimalSeparator=","
                    thousandSeparator=""
                    prefix="R$ "
                    value={valorBrotinho}
                    onChangeEvent={(event) =>
                      setValorBrotinho(event.target.value)
                    }
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>

          <Grid
            container
            spacing={5}
            direction="row"
            alignItems="center"
            justify="center"
          >
            {categoriaSelecionada && !categoriaPizzas ? (
              <>
                <br />

                <Grid md={6} item>
                  <InputLabel className={classes.labelMoney}>Valor</InputLabel>

                  <CurrencyInput
                    className={classes.inputMoney}
                    decimalSeparator=","
                    thousandSeparator=""
                    prefix="R$ "
                    value={valorProduto}
                    onChangeEvent={(event) =>
                      setValorProduto(event.target.value)
                    }
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
            <br />
            <br />

            {categoriaSelecionada != null ? (
              <>
                <input
                  accept="image/*"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="raised-button-file"
                  onChange={changeFile}
                  type="file"
                />
                <label htmlFor="raised-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    className={classes.cardBodyDark}
                  >
                    {imagemNome}
                  </Button>{" "}
                </label>
                <br />
                {imagemProduto ? (
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      component="span"
                      color="default"
                      className={classes.cardBodyDark}
                      onClick={() => {
                        setImagemNome("Escolha uma imagem");
                        setImagemProduto();
                        setImagePreviewUrl();
                      }}
                    >
                      Remover Imagem
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <Grid item md={12} style={{ textAlign: "center" }}>
                  {imagePreviewUrl ? (
                    <img src={imagePreviewUrl} className={classes.media} />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={12}>
                  <Button
                    onClick={handleCadastraProduto}
                    variant="contained"
                    className={classes.cadastrarButton}
                  >
                    Cadastrar
                  </Button>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </div>
      )}
    </>
  );
}
