import React, { useEffect, useState } from "react";
import swal from "sweetalert2";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import PersonIcon from "@material-ui/icons/Person";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#333",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),

    "&$focused": {
      color: "tomato",
      fontWeight: "bold",
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#333",
    transition: "0.4s",
    "&:hover": { backgroundColor: "#fff", color: "#333" },
  },

  heroContainer: {
    height: "100vh",
    background:
      "linear-gradient(to bottom, #333333, #363636, #3a393a, #3e3b3d, #423e3f, #543f43, #664043, #774140, #973d3b, #b53533, #d22926, #ee1313);",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "calc(100vw)",

    padding: 24,
  },
  textField: {
    color: "#fff",
    background: "#fff",
  },
  input: {
    color: "black",
    background: "#fff",
    borderRadius: "5px",
  },
  text: {
    color: "white",
  },
  imgLogin: {
    width: 111,
    heigh: 111,
  },
  imgLoginDiv: {
    display: "flex",
    alignItems: "right",
  },
}));

function Copyright() {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.text}
    >
      {"Desenvolvido por "}
      <Link color="inherit" href="https://easysimp.com.br/">
        Easy Simp
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    if (!username || !password) {
      swal.fire({
        html: "<h4>Preencha o Usuário e a Senha!</h4>",
        icon: "warning",
        confirmButtonColor: "#2d3035",
        confirmButtonText: "Ok",
        timer: 5000,
      });
    } else {
      const data = {
        username,
        password,
      };

      await api.post("login-user", data, { timeout: 4000 }).then((response) => {
        //console.log(response);
        if (response.data.token) {
          //console.log(response.data);
          swal.fire({
            html: `<h4>Bem vindo de volta ${response.data.user.name} :)</h4>`,
            icon: "success",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 5000,
          });
          localStorage.setItem("token", response.data.token.token);
          localStorage.setItem("company", response.data.user.company);
          localStorage.setItem("user_type", response.data.user.type);
          localStorage.setItem("name", response.data.user.name);
          localStorage.setItem("user_id", response.data.user.id);
          localStorage.setItem("darkMode", "sim");
          setToken(response.data.token.token);
          setTimeout(function () {
            window.location.href = "/";
          }, 1500);
        }
        if (response.data === "User not allowed") {
          swal.fire({
            html: "<h4>Usuário não tem permissão!</h4>",
            icon: "error",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 5000,
          });
        }
        if (response.data.passwordField === "password") {
          swal.fire({
            html: "<h4>Senha incorreta!</h4>",
            icon: "error",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 5000,
          });
        }
        if (response.status === 204) {
          swal.fire({
            html: "<h4>Usuário não encontrado</h4>",
            icon: "error",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 5000,
          });
        }
      });
    }
  }

  return (
    <Paper className={classes.heroContainer}>
      <div className={classes.imgLoginDiv}></div>
      <img src="/static/img/logo.svg" alt="" className={classes.imgLogin} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PersonIcon fontSize="large" />
          </Avatar>
          <Typography component="h1" variant="h5" className={classes.text}>
            Login
          </Typography>
          <form className={classes.form} noValidate autoComplete="off">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Usuário"
              name="username"
              onChange={(event) => {
                setUsername(event.target.value);
              }}
              InputProps={{
                className: classes.input,
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              InputProps={{
                className: classes.input,
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" className={classes.text}>
                  Esqueceu sua senha?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Paper>
  );
}
