import { combineReducers } from "redux";

import abreLoja from "./isOpen";
import pedidosNaoAceitos from "./pedidosNaoAceitos";
import isDark from "./isDark";

export default combineReducers({
  abreLoja,
  pedidosNaoAceitos,
  isDark,
});
