import {
  drawerWidth,
  transition,
  container,
} from "assets/jss/material-dashboard-react.js";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    transition: "1.5s",
  },
  wrapperDark: {
    position: "relative",
    top: "0",
    height: "100vh",
  },

  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",

    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    transition: "1s !important",
  },
  mainPanelDark: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",

    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    background: "#353535",
    transition: "1.5s",
    color: "#fff",
  },
  content: {
    marginTop: "80px",

    minHeight: "calc(100vh - 123px)",
  },
  container,
});

export default appStyle;
