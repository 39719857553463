import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../../services/api";

import swal from "sweetalert2";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SwitchButton from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import Select from "@material-ui/core/Select";

import mapStyle0 from "./mapStyle0";
import mapStyle1 from "./mapStyle1";
import mapStyle2 from "./mapStyle2";
import mapStyle3 from "./mapStyle3";

import "./styles.css";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Map = () => {
  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="down" ref={ref} {...props} />;
  // });
  const store = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [mapStyle, setMapStyle] = useState(
    store.isDark.status ? mapStyle1 : mapStyle0
  );

  const [areaId, setAreaId] = useState(0);
  const [nomeArea, setNomeArea] = useState("");
  const [taxaArea, setTaxaArea] = useState(0);
  const [tempoArea, setTempoArea] = useState(0);
  const [areaAtiva, setAreaAtiva] = useState(false);
  const [coordsArea, setCoodsArea] = useState([]);
  const [fillColor, setFillColor] = useState("");
  const [areasEntrega, setAreasEntrega] = useState([]);

  const [buttonColors, setButtonColors] = useState([]);

  const [novaArea, setNovaArea] = useState(0);
  const [updateButton, setUpdateButton] = useState(false);

  const token = localStorage.getItem("token");

  var selectedShape;
  useEffect(() => {
    var drawingManager;
    var selectedShape;
    var infoWindow;
    var colors = [
      { color: "#1E90FF" },
      { color: "#4B0082" },
      { color: "#32CD32" },
      { color: "#FF8C00" },
      { color: "#000099" },
      { color: "#f2000e" },
      { color: "#FF1493" },
      { color: "#ff1d58" },
    ];
    setButtonColors(colors);
    var selectedColor;
    var colorButtons = {};

    function clearSelection() {
      setUpdateButton(false);
      if (selectedShape) {
        if (selectedShape.type !== "marker") {
          selectedShape.setEditable(false);
        }

        selectedShape = null;
      }
    }

    function setSelection(shape) {
      if (shape.type !== "marker") {
        clearSelection();
        shape.setEditable(true);
        selectColor(shape.get("fillColor") || shape.get("strokeColor"));
        setFillColor(shape.get("fillColor"));
      }

      selectedShape = shape;
    }

    function deleteSelectedShape() {
      if (selectedShape) {
        selectedShape.setMap(null);
      }
    }

    function selectColor(color) {
      selectedColor = color;
      for (var i = 0; i < colors.length; ++i) {
        var currColor = colors[i];
        colorButtons[currColor].style.border =
          currColor == color ? "2px solid #789" : "2px solid #fff";
      }

      // Retrieves the current options from the drawing manager and replaces the
      // stroke or fill color as appropriate.
      // var polylineOptions = drawingManager.get("polylineOptions");
      // polylineOptions.strokeColor = color;
      // drawingManager.set("polylineOptions", polylineOptions);

      // var rectangleOptions = drawingManager.get("rectangleOptions");
      // rectangleOptions.fillColor = color;
      // drawingManager.set("rectangleOptions", rectangleOptions);

      // var circleOptions = drawingManager.get("circleOptions");
      // circleOptions.fillColor = color;
      // drawingManager.set("circleOptions", circleOptions);

      var polygonOptions = drawingManager.get("polygonOptions");
      polygonOptions.fillColor = color;
      drawingManager.set("polygonOptions", polygonOptions);
    }

    function setSelectedShapeColor(color) {
      if (selectedShape) {
        if (
          selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE
        ) {
          selectedShape.set("strokeColor", color);
        } else {
          selectedShape.set("fillColor", color);
        }
      }
    }

    function makeColorButton(color) {
      var button = document.createElement("span");
      button.className = "color-button";
      button.style.backgroundColor = color;
      window.google.maps.event.addDomListener(button, "click", function() {
        selectColor(color);
        setSelectedShapeColor(color);
      });

      return button;
    }

    function buildColorPalette() {
      var colorPalette = document.getElementById("color-palette");
      for (var i = 0; i < colors.length; ++i) {
        var currColor = colors[i];
        var colorButton = makeColorButton(currColor);
        colorPalette.appendChild(colorButton);
        colorButtons[currColor] = colorButton;
      }
      selectColor(colors[0]);
    }

    function initialize() {
      var map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 14,
        center: { lat: -27.75055377, lng: -48.51255119 },
        disableDefaultUI: true,
        zoomControl: true,
        styles: mapStyle,
      });
      var myLatlng = new window.google.maps.LatLng(-27.75055377, -48.51255119);
      var marker = new window.google.maps.Marker({
        position: myLatlng,
        map: map,
        title: "Yoo House",
      });
      marker.setMap(map);
      var polyOptions = {
        strokeWeight: 0,
        fillOpacity: 0.45,
        editable: true,
        // draggable: true,
      };

      const buscaAreasEntregas = async () => {
        await api
          .get(
            "busca-areas-entregas",
            { headers: { Authorization: `Bearer ${token}` } },
            { timeout: 10000 }
          )
          .then((response) => {
            setAreasEntrega(response.data);
            response.data.map((areaEntrega) => {
              areaEntrega.id = new window.google.maps.Polygon({
                paths: areaEntrega.coords_area,
                strokeColor: "#000",
                strokeOpacity: 0.8,
                strokeWeight: 0.5,
                fillColor: areaEntrega.fillColor,
                fillOpacity: 0.45,
                name: areaEntrega.nome_area,
                idArea: areaEntrega.id,
                taxaEntrega: areaEntrega.taxa_entrega,
                tempoEntrega: areaEntrega.tempo_entrega,
                ativa: areaEntrega.ativa,
              });
              areaEntrega.id.setMap(map);

              areaEntrega.id.addListener("click", (e) => {
                setSelection(areaEntrega.id);
                setNomeArea(areaEntrega.id.name);
                setAreaId(areaEntrega.id.idArea);
                setTaxaArea(areaEntrega.id.taxaEntrega);
                setTempoArea(areaEntrega.id.tempoEntrega);
                setAreaAtiva(areaEntrega.id.ativa);
                setCoodsArea(areaEntrega.id.coordsArea);
                setOpenUpdateDialog(true);
              });

              window.google.maps.event.addListener(
                areaEntrega.id.getPath(),
                "insert_at",
                function(e) {
                  console.log("ta nexendo insert");
                  var coordinatesArray = areaEntrega.id.getPath();
                  var coords = [];
                  for (var i = 0; i < coordinatesArray.getLength(); i++) {
                    var xy = coordinatesArray.getAt(i);
                    coords.push({ lat: xy.lat(), lng: xy.lng() });
                  }
                  console.log(coords);
                  setCoodsArea(JSON.stringify(coords));
                }
              );
              window.google.maps.event.addListener(
                areaEntrega.id.getPath(),
                "set_at",
                function(e) {
                  console.log("ta nexendo set");
                  var coordinatesArray = areaEntrega.id.getPath();
                  var coords = [];
                  for (var i = 0; i < coordinatesArray.getLength(); i++) {
                    var xy = coordinatesArray.getAt(i);
                    coords.push({ lat: xy.lat(), lng: xy.lng() });
                  }
                  console.log(coords);
                  setCoodsArea(JSON.stringify(coords));
                }
              );

              // attachPolygonInfoWindow(areaEntrega.id);

              // function attachPolygonInfoWindow(polygon) {
              //   var infoWindow = new window.google.maps.InfoWindow();
              //   window.google.maps.event.addListener(
              //     polygon,
              //     "mouseover",
              //     function(e) {
              //       infoWindow.setContent(
              //         `<b>${areaEntrega.id.name}</b><br><br>Taxa de Entreha: ${
              //           areaEntrega.id.taxaEntrega
              //         }<br>Tempo Previsto: ${
              //           areaEntrega.id.tempoEntrega
              //         } (min)<br>Ativa: ${areaEntrega.id.ativa ? "Sim" : "Não"}`
              //       );
              //       var latLng = e.latLng;
              //       infoWindow.setPosition(latLng);
              //       infoWindow.open(map);
              //     }
              //   );
              //   window.google.maps.event.addListener(
              //     polygon,
              //     "mouseout",
              //     function(e) {
              //       infoWindow.close();
              //     }
              //   );
              // }
            });
          })
          .catch((error) => {
            swal.fire({
              html: "<h4>Ocorreu algum erro de conexão</h4>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              timer: 2500,
            });
          });
      };
      buscaAreasEntregas();

      // Creates a drawing manager attached to the map that allows the user to draw
      // markers, lines, and shapes.
      drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
          drawingModes: [],
        },
        markerOptions: {
          draggable: true,
        },
        polylineOptions: {
          editable: true,
          draggable: true,
        },
        rectangleOptions: polyOptions,
        circleOptions: polyOptions,
        polygonOptions: polyOptions,
        map: map,
      });

      infoWindow = new window.google.maps.InfoWindow();

      window.google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        function(e) {
          setOpen(true);

          var contentString = " ";
          var coordinatesArray = e.overlay.getPath();

          var coords = [];
          for (var i = 0; i < coordinatesArray.getLength(); i++) {
            var xy = coordinatesArray.getAt(i);

            coords.push({ lat: xy.lat(), lng: xy.lng() });
            contentString +=
              "<br>" +
              "Coord " +
              i +
              ":<br>lat: " +
              xy.lat() +
              ", lng: " +
              xy.lng();
          }

          setCoodsArea(JSON.stringify(coords));
          // console.log(JSON.stringify(coords));

          infoWindow.setContent(contentString);
          infoWindow.setPosition({ lat: -27.7589981, lng: -48.5087219 });
          // infoWindow.open(map);

          var newShape = e.overlay;

          newShape.type = e.type;

          if (e.type !== window.google.maps.drawing.OverlayType.MARKER) {
            // Switch back to non-drawing mode after drawing a shape.
            drawingManager.setDrawingMode(null);

            // Add an event listener that selects the newly-drawn shape when the user
            // mouses down on it.
            window.google.maps.event.addListener(newShape, "click", function(
              e
            ) {
              console.log(e.vertex);
              if (e.vertex !== undefined) {
                if (
                  newShape.type ===
                  window.google.maps.drawing.OverlayType.POLYGON
                ) {
                  var path = newShape.getPaths().getAt(e.path);
                  path.removeAt(e.vertex);
                  if (path.length < 3) {
                    newShape.setMap(null);
                  }
                }
                if (
                  newShape.type ===
                  window.google.maps.drawing.OverlayType.POLYLINE
                ) {
                  var path = newShape.getPath();
                  path.removeAt(e.vertex);
                  if (path.length < 2) {
                    newShape.setMap(null);
                  }
                }
              }
              setSelection(newShape);
            });
            setSelection(newShape);
          } else {
            window.google.maps.event.addListener(newShape, "click", function(
              e
            ) {
              setSelection(newShape);
            });
            setSelection(newShape);
          }

          contentString = null;
        }
      );

      // Clear the current selection when the drawing mode is changed, or when the
      // map is clicked.
      window.google.maps.event.addListener(
        drawingManager,
        "drawingmode_changed",
        clearSelection
      );
      window.google.maps.event.addListener(map, "click", clearSelection);
      window.google.maps.event.addDomListener(
        document.getElementById("delete-button"),
        "click",
        deleteSelectedShape
      );
      window.google.maps.event.addDomListener(
        document.getElementById("cancel-button"),
        "click",
        deleteSelectedShape
      );
      document
        .getElementById("create-button")
        .addEventListener("click", function() {
          var letters = "0123456789ABCDEF";
          var color = "#";
          for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          if (color !== "#FFFFFF" || color !== "#333333") {
            selectColor(color);
            setFillColor(color);
          } else {
            selectColor("#4B0082");
            setFillColor("#4B0082");
          }

          drawingManager.setDrawingMode("polygon");
        });
      // window.addDomListener(
      //   document.getElementById(""),
      //   "click",
      //   drawingManager.setDrawingMode("polygon")
      // );

      buildColorPalette();
    }
    initialize();
  }, [mapStyle, novaArea]);

  const handleClose = () => {
    setOpen(false);
    document.getElementById("cancel-button").click();
  };

  const insereArea = async () => {
    const dataArea = {
      nome_area: nomeArea,
      taxa_entrega: taxaArea,
      tempo_entrega: tempoArea,
      coords_area: coordsArea,
      fillColor,
    };

    await api
      .post(
        "insere-area-entrega",
        dataArea,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then((response) => {
        if (response.data) {
          setOpen(false);
          setNovaArea(novaArea + 1);
          setTimeout(() => {
            swal.fire({
              html:
                "<h4>Nova área de entrega cadastrada com sucesso</h4><br />",
              icon: "success",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              timer: 5000,
            });
          }, 200);
        }
      })
      .catch(() => {
        swal
          .fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            //  timer: 5000
          })
          .then(() => {
            // window.location.reload();
          });
        // setTimeout(() => window.location.reload(), 5000);
      });
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
  };

  const handleChangeAtiva = (e) => {
    if (e.target.checked) {
      setAreaAtiva(true);
    } else {
      setAreaAtiva(false);
      setFillColor("#333333");
    }
  };

  const deleteArea = async () => {
    setOpenUpdateDialog(false);
    await api
      .delete(
        `excluir-area-entrega/${areaId}`,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then((response) => {
        if (response.data) {
          swal.fire({
            html: "<h4>A área de entrega foi excluída com sucesso</h4>",
            icon: "error",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 3500,
          });
          setNovaArea(novaArea + 1);
        }
      });
  };
  const updateArea = async () => {
    const dataArea = {
      nome_area: nomeArea,
      taxa_entrega: taxaArea,
      tempo_entrega: tempoArea,
      coords_area: coordsArea,
      ativa: areaAtiva,
      fillColor,
    };
    setOpenUpdateDialog(false);
    setUpdateButton(false);
    await api
      .put(
        `atualizar-area-entrega/${areaId}`,
        dataArea,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then((response) => {
        if (response.data) {
          swal.fire({
            html: "<h4>A área de entrega foi atualizada com sucesso</h4>",
            icon: "success",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 3500,
          });
          setNovaArea(novaArea + 1);
        }
      });
  };

  const handleUpadatePerimetro = () => {
    setOpenUpdateDialog(false);
    setUpdateButton(true);
  };

  return (
    <>
      <div id="panel">
        <div id="color-palette"> </div>

        <div>
          <Button id="cancel-button" color="primary" />

          <Button
            variant="contained"
            className="create-button"
            id="delete-button"
          >
            Excluir Área
          </Button>

          <Button
            variant="contained"
            className="create-button"
            id="create-button"
          >
            Criar Área
          </Button>

          {updateButton ? (
            <Button
              variant="contained"
              className="create-button"
              id="update-button"
              onClick={updateArea}
            >
              Atualizar Área
            </Button>
          ) : (
            ""
          )}
          <span id="style-select">
            <FormControl>
              {/* <InputLabel id="demo-simple-select-label">
                Estilo do Mapa
              </InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mapStyle}
                onChange={(e) => setMapStyle(e.target.value)}
              >
                <MenuItem value={mapStyle0}>Original</MenuItem>
                <MenuItem value={mapStyle1}>Estilo 1</MenuItem>
                <MenuItem value={mapStyle2}>Estilo 2</MenuItem>
                <MenuItem value={mapStyle3}>Estilo 3</MenuItem>
              </Select>
            </FormControl>
          </span>
          {/* <Button
            variant="contained"
            className="style-button"
            size="small"
            onClick={() => setMapStyle(mapStyle1)}
          >
            Style 1
          </Button>
          <Button
            variant="contained"
            className="style-button"
            size="small"
            onClick={() => setMapStyle(mapStyle2)}
          >
            Style 2
          </Button>
          <Button
            variant="contained"
            className="style-button"
            size="small"
            onClick={() => setMapStyle(mapStyle3)}
          >
            Style 3
          </Button> */}
        </div>
      </div>

      <div id="map"></div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title">Área de Entrega</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor defina as configurações para esta área
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome da Área"
            type="text"
            onChange={(e) => setNomeArea(e.target.value)}
            fullWidth
          />
          <br />
          <TextField
            margin="dense"
            id="taxa"
            label="Taxa de Entrega"
            type="number"
            onChange={(e) => setTaxaArea(e.target.value)}
            fullWidth
          />
          <br />
          <TextField
            margin="dense"
            id="tempo"
            label="Tempo Estimado para Entrega (min)"
            type="number"
            onChange={(e) => setTempoArea(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <br />
        <br />
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Cancelar
          </Button>
          <Button
            onClick={insereArea}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpdateDialog}
        onClose={handleCloseUpdateDialog}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title">Editar Área de Entrega</DialogTitle>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor defina as novas configurações para esta área
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome da Área"
            type="text"
            value={nomeArea}
            onChange={(e) => setNomeArea(e.target.value)}
            fullWidth
          />
          <br />
          <TextField
            margin="dense"
            id="taxa"
            label="Taxa de Entrega"
            type="number"
            value={taxaArea}
            onChange={(e) => setTaxaArea(e.target.value)}
            fullWidth
          />
          <br />
          <TextField
            margin="dense"
            id="tempo"
            label="Tempo Estimado para Entrega (min)"
            type="number"
            value={tempoArea}
            onChange={(e) => setTempoArea(e.target.value)}
            fullWidth
          />
          <br />
          <br />
          <FormControlLabel
            label={areaAtiva ? `Entrega Ativada` : `Entrega Inativada`}
            control={
              <SwitchButton
                checked={areaAtiva}
                onChange={handleChangeAtiva}
                name="checkedB"
                color="secondary"
              />
            }
          />
          <br /> <br />
          Trocar a Cor
          <br /> <br />
          {buttonColors.map((button) => (
            <>
              <Button
                className="color-button"
                style={{ background: `${button.color}` }}
                onClick={() => setFillColor(button.color)}
              />
            </>
          ))}
        </DialogContent>

        <br />
        <DialogActions>
          <Button
            onClick={deleteArea}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
            size="small"
          >
            Excluir Área
          </Button>
          <Button
            onClick={handleUpadatePerimetro}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
            size="small"
          >
            Atualizar Perimetro
          </Button>
        </DialogActions>
        <DialogActions>
          <Button
            onClick={handleCloseUpdateDialog}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Cancelar
          </Button>

          <Button
            onClick={updateArea}
            style={{ flex: "1 0 0" }}
            variant="contained"
            className="dialog-button"
          >
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Map;
