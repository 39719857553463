const PEDIDOS_NAO_ACEITOS = {
  number: 0,
};

export default function pedidosNaoAceitos(state = PEDIDOS_NAO_ACEITOS, action) {
  switch (action.type) {
    case "PEDIDOS_NAO_ACEITOS":
      return { number: action.number };
    default:
      return state;
  }
}
