import React, { useEffect, useState } from "react";
import api from "../services/api";

import { Switch, Route, Redirect } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import yoodemoSocket from "services/webSocket";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import Snackbar from "@material-ui/core/Snackbar";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Slide from "@material-ui/core/Slide";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import SwitchButton from "@material-ui/core/Switch";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/layouts/darkAdminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo.png";

import "./styles.css";

// import yoodemoSocket from "../services/webSocket";

let ps;

function TransitionDown(props) {
  return <Slide {...props} direction="down" timeout={2000} />;
}

function TransitionRight(props) {
  return <Slide {...props} direction="right" timeout={2000} />;
}

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

// const socket =
//   yoodemoSocket.getSubscription('japa') ||
//   yoodemoSocket.subscribe('japa');

export default function Admin({ ...rest }) {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    const dark = localStorage.getItem("darkMode");
    if (dark == "sim") {
      dispatch({ type: "IS_DARK", status: true });
    } else {
      dispatch({ type: "IS_DARK", status: false });
    }

    const falaPedido = () => {
      var msg = new SpeechSynthesisUtterance();
      msg.volume = 1; // 0 to 1
      msg.text = "Novo pedido no site, bora faturar";
      msg.lang = "pt-BR";
      const audio = document.getElementsByClassName("audio-element")[0];
      audio.loop = true;
      audio.play();
      msg.onend = function (e) { };
      speechSynthesis.speak(msg);
    };

    const alien = document.getElementsByClassName("audio-element-cliente")[0];

    const dropAudio = document.getElementsByClassName("audio-element-drop")[0];

    const socket =
      yoodemoSocket.getSubscription('japa') ||
      yoodemoSocket.subscribe('japa');

    socket.on("novoClienteRegistradoSite", (data) => {
      setEstabelecimento(data.estabelecimento);
      setLuz(true);
      alien.play();
      setTimeout(() => {
        dropAudio.play();
      }, 3800);
      setTimeout(() => {
        setOpenNotification(true);
        setLuz(false);
      }, 8000);
    });

    socket.on("novoPedidoAberto", () => {
      falaPedido();
      setOpenNotificationNovoPedido(true);
    });

    const verificaNovoPedido = async () => {
      await api
        .get(
          "busca-novo-pedido",
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          if (response.data.new) {
            falaPedido();
            setOpenNotificationNovoPedido(true);
          }
        })
    }

    setInterval(() => {
      verificaNovoPedido()
    }, 60000);
  }, []);

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState("");
  const [color, setColor] = React.useState("red");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [openNotificationNovoPedido, setOpenNotificationNovoPedido] = useState(
    false
  );
  const [estabelecimento, setEstabelecimento] = useState("yoo");
  const [luz, setLuz] = useState(false);
  const [userName, setUserName] = useState("User")

  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const handleClose = () => {
    setOpenNotification(false);
    setOpenNotificationNovoPedido(false);
    const audio = document.getElementsByClassName("audio-element")[0];
    audio.pause();
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    const name = localStorage.getItem("name")
    setUserName(name)
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  // socket.on("novoPedidoAberto", () => {
  //   console.log("oi");
  //   openNotification();
  // });

  return (
    <>
      {luz ? <div id="luz">&nbsp;</div> : ""}
      <Snackbar
        key={"yoo"}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transitionDuration={3500}
        open={openNotification}
        autoHideDuration={360000}
        TransitionComponent={TransitionDown}
        onClose={handleClose}
        message={`Novo Cliente Cadastrado no Site: ${estabelecimento}`}
        action={
          <React.Fragment>
            <EmojiEmotionsIcon />
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        key={"yoo"}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transitionDuration={3500}
        open={openNotificationNovoPedido}
        autoHideDuration={360000}
        TransitionComponent={TransitionRight}
        onClose={handleClose}
        message={`Novo Pedido no Site`}
        action={
          <React.Fragment>
            <EmojiEmotionsIcon />
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={" Easy Simp"}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          userName={userName}
          {...rest}
        />
        <div className={classes.mainPanel} ref={mainPanel}>
          <Navbar
            routes={routes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}

          {getRoute() ? <Footer /> : null}
        </div>
      </div>
      <audio className="audio-element">
        <source src="/static/audio/pedido.mp3"></source>
      </audio>
      <audio className="audio-element-cliente">
        <source src="/static/audio/cliente.mp3"></source>
      </audio>{" "}
      <audio className="audio-element-drop">
        <source src="/static/audio/drop.mp3"></source>
      </audio>
    </>
  );
}
