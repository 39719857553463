import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import MomentUtils from "@date-io/moment";

import store from "./store";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import yoodemoSocket from "services/webSocket";

import { SmileOutlined } from "@ant-design/icons";

import { notification } from "antd";
import "antd/dist/antd.css";
// core components
import Admin from "layouts/Admin.js";
import Login from "views/Login/Login.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";

const hist = createBrowserHistory();

const token = localStorage.getItem("token");

const falaPedido = () => {
  var msg = new SpeechSynthesisUtterance();
  msg.volume = 1; // 0 to 1
  msg.text = "Novo pedido no site, bora faturar";
  msg.lang = "pt-BR";
  const audio = document.getElementsByClassName("audio-element")[0];
  audio.loop = true;
  audio.play();
  msg.onend = function (e) { };
  speechSynthesis.speak(msg);
};

const openNotification = () => {
  notification.open({
    message: "Novo Pedido no Site",
    description: "Um parágrafo qualquer :)",
    placement: "bottomRight",
    icon: <SmileOutlined style={{ color: "#000099" }} />,

    onClick: () => {
      const audio = document.getElementsByClassName("audio-element")[0];
      audio.pause();
      // alert("ir para pedido");
    },
    onClose: () => {
      const audio = document.getElementsByClassName("audio-element")[0];
      audio.pause();
    },
    duration: 45,
  });
};

const socket =
  yoodemoSocket.getSubscription('japa') ||
  yoodemoSocket.subscribe('japa');

const user_id = localStorage.getItem("user_id");

socket.emit("novoUserOnline", {
  user_id,
  type: "admin",
});

socket.on("novoPedidoAberto", () => {
  // falaPedido();
  // openNotification();
});

ReactDOM.render(
  <Router history={hist}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Switch>
        {!token ? (
          <>
            <Route path="/login" exact component={Login} />
            <Redirect to="/login" />
          </>
        ) : (
          <>
            <Provider store={store}>
              <Route path="/admin" component={Admin} />
              {/* <Route path="/login" exact component={Login} /> */}

              <Redirect from="/" exact to="/admin/dashboard" />
            </Provider>
          </>
        )}
      </Switch>
    </MuiPickersUtilsProvider>
  </Router>,

  document.getElementById("root")
);
